import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactFormService } from 'src/app/services/contact-form.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-ssologin',
  templateUrl: './ssologin.component.html',
  styleUrls: ['./ssologin.component.css']
})
export class SsologinComponent implements OnInit {

  loginForm: FormGroup;
  showLoadingIndicator = false;
  otpForm: FormGroup;

  constructor(
    private fb:FormBuilder,
    private service : ContactFormService,
    private toastr: ToastrService,
    private router: Router,
    public dialogRef: MatDialogRef<SsologinComponent>,
    public dialog: MatDialog,

    // @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[A-Za-z0-9._%+-]+@testyantra\.com|in$/),
        Validators.email,
      ]),
    })

    this.otpForm=this.fb.group({
      otp:['',Validators.required]
    },
    {
      validator: this.checkLength('otp')
    })
   }

  ngOnInit(): void {
  }

isSelected:boolean=false

  backbtn(event){
    this.isSelected=false
  }
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,

  };

  checkLength(otp: string) {
    return (formGroup: FormGroup) => {
      const otpLength = formGroup.controls[otp]
      if ((otpLength.value.length && otpLength.value.length < 6)) {
        otpLength.setErrors({
          length: true
        })
      } else {
        otpLength.setErrors({
          length: false
        })
      }
    }
  }

  onOtpChange(event:string) {
    this.otpForm.get('otp')?.setValue(event);
  }


submitbtn(event){
  this.showLoadingIndicator=true
   this.service.login(this.loginForm.value).subscribe(res=>{
    if (!res.error) {
      this.showLoadingIndicator=false
      this.toastr.success(res.message);
      this.isSelected=true
      localStorage.setItem('UserDetails',JSON.stringify(this.loginForm.value))
    }
   },(err) => {
    if (err.status) {
      this.showLoadingIndicator=false
      this.toastr.error(err.error.message);
      this.isSelected=false

    } else {
      this.toastr.error('CONNECTION_ERROR');
      this.showLoadingIndicator=false
      this.isSelected=false
    }
  });
}


onSubmit(){
  let user = JSON.parse(localStorage.getItem('UserDetails') as string);
     this.showLoadingIndicator=true
     let userdata={
      emailId:user.emailId,
  otp:this.otpForm.value.otp,
 }
 this.service.VerifyOtpData(userdata).subscribe((res) => {
  if (!res.error) {
    this.showLoadingIndicator=false
    localStorage.setItem('otpResponse', JSON.stringify(res.data))
    this.toastr.success(res.message);
    this.dialogRef.close()
    this.router.navigate([]).then(result => {window.open(`dashboard/homes` ,  '_blank'); });
  }
 },(err) => {
   if (err.status) {
    this.showLoadingIndicator=false
    this.toastr.error(err.error.message);
  } else {
    this.showLoadingIndicator=false
    this.toastr.error('CONNECTION_ERROR');
  }
});

}


resendOtp(){
  let resendotp=document.getElementById('refresh')
  console.log(resendotp);
  resendotp.classList.add('refresh');

  setTimeout (() => {
    resendotp.classList.remove('refresh');
 }, 20000);
 let user = JSON.parse(localStorage.getItem('UserDetails') as string);
 this.showLoadingIndicator=true

  let userdata={
    emailId:user.emailId,
   }
  this.service.ResendOtp(userdata).subscribe((res) => {
    if (!res.error) {
      this.showLoadingIndicator=false
      localStorage.setItem('otpResponse', JSON.stringify(res.data))
      this.toastr.success(res.message);
      this.dialogRef.close()
      const dialogRefs= this.dialog.open(SsologinComponent,{
        width: '339px',
        height: '48%',
        panelClass:'ssologin'
      });
      dialogRefs.afterClosed().subscribe((result) => {
        if (result === 'update') {
        }
      });    }
   },(err) => {
     if (err.status) {
      this.showLoadingIndicator=false
      this.toastr.error(err.error.message);
    } else {
      this.showLoadingIndicator=false
      this.toastr.error('CONNECTION_ERROR');
    }
  });
}


}
