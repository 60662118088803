<!-- <section class="page-title abt-bg">
    <div class="col-lg-12 text-center">
        <div style="display: inline-block; vertical-align: super">
            <div class="title-branch-menu" style="vertical-align: super">
                Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
            </div>
        </div>
        <div style="display: inline-block">
            <h1 class="text-lg">School Expert System (SES)</h1>
        </div>
    </div>
</section> -->
<section>
    <div>
        <img src="assets/images/Case study/Education.png" alt="" style="position: relative;">
    </div>
</section>

<div class="container-fluid">
    <!-- <div class="row mb-5 mt-5">
      <div class="col-md-2"></div>
      <div class="col-md-10">
          <h4 class="text-secondary">Company</h4>
          <h1 class="mt-4 company-name">Mr. & Mrs. Cart</h1>
      </div>
  </div> -->
    <div class="row mt-5">
        <div class="col-md-2 mt-4"></div>
        <div class="col-md-8 mt-4">
            <div class="row">
                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Region.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-map-marker-alt"></i> -->
                    </div>
                    <h4 class="title">Region</h4>
                    <h2>India</h2>
                </div>

                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Industry.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-industry"></i> -->
                    </div>
                    <h4 class="title">Industry</h4>
                    <h2>Education</h2>
                </div>

                <div class="col-md-4 details1">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Technologies.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-laptop-code"></i> -->
                    </div>
                    <h4 class="title">Technologies</h4>
                    <h2>Java Springboot, ReactJS- Next.js Framework, PostgresSQL.</h2>
                </div>
            </div>
        </div>
        <div class="col-md-2 mt-4"></div>
    </div>

    <!-- <div class="text-center mt-5">
</div> -->

    <div class="row mt-5 mb-5">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="card border-0 block1">
                        <div class="detail-icon1">
                            <img src="assets/images/Case study/requirement.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="fas fa-clipboard"></i> -->
                        </div>
                        <h3 style="text-align: center;">Requirement</h3>
                        <p>
                            Digitizing the entire school administration process, transforming a traditional school system into a computerized system. Developing a customizable HRMS application that handles attendance, human resource management (HRM), examination & scheduling, payroll
                            management, inventory, purchase, employee management, department management and reporting system modules.
                        </p>
                    </div>
                    <div class="card border-0 block2">
                        <div class="detail-icon2">
                            <img src="assets/images/Case study/challenges.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-lightbulb"></i> -->
                        </div>
                        <h3 style="text-align: center;">Challenges</h3>
                        <p>
                            Structuring of the data as per the module requirement in terms of organising and classification posed technical difficulties. It was difficult to organise and model data, as well as upgrade technology compatibility. It was challenging to make the platform
                            accessible with a generic schooling system and facilities to configure for an independent work flow of the system.
                        </p>
                    </div>
                    <div class="card border-0 block3">
                        <div class="detail-icon3">
                            <img src="assets/images/Case study/result.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-thumbs-up"></i> -->
                        </div>
                        <h3 style="text-align: center;">Result</h3>
                        <p>
                            Developed a digitalized, user-friendly application that can be completely customized to meet school/board specific requirements. It features a user-friendly interface, drag-and-drop functionality, and graphic representations. SES is an HRMS designed to
                            handle all aspects of school administration, including exam scheduling, exam administration, payroll, attendance, detailing, and reporting.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-1"></div>
        </div>
    </div>
