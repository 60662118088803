import { Component } from '@angular/core';

@Component({
  selector: 'app-telecom',
  templateUrl: './telecom.component.html',
  styleUrls: ['./telecom.component.css']
})
export class TelecomComponent {

  constructor() {
    document.title = 'Case Study | Telecom | TekPyramid';
  }

}
