import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {

  constructor() 
  {
    document.title = "Job | TekPyramid";
   }

  ngOnInit(): void {
  }

}
