import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactusFormComponent } from '../components/home/contactus-form/contactus-form.component';
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list'
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table'
import {MatTooltipModule} from '@angular/material/tooltip'
@NgModule({
  declarations: [
    ContactusFormComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatDialogModule,
    MatListModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule
  ],
  exports:[
    ContactusFormComponent,
    CommonModule,
    MatListModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule

  ]
})
export class SharedModule { }
