<!-- <section class="page-title abt-bg">
    <div class="col-lg-12 text-center">
        <div style="display: inline-block; vertical-align: super">
            <div class="title-branch-menu" style="vertical-align: super">
                Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
            </div>
        </div>
        <div style="display: inline-block">
            <h1 class="text-lg">Digital Visitor Management System</h1>
        </div>
    </div>
</section> -->
<section>
    <div>
        <img src="assets/images/Case study/Technology (vms).png" alt="" style="position: relative;">
    </div>
</section>

<div class="container-fluid">
    <!-- <div class="row mb-5 mt-5">
      <div class="col-md-2"></div>
      <div class="col-md-10">
          <h4 class="text-secondary">Company</h4>
          <h1 class="mt-4 company-name">Hancom</h1>
      </div>
  </div> -->

    <div class="row mt-5">
        <div class="col-md-2 mt-4"></div>

        <div class="col-md-8 mt-4">
            <div class="row">
                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Region.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-map-marker-alt"></i> -->
                    </div>
                    <h4 class="title">Region</h4>
                    <h2>India</h2>
                </div>

                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Industry.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-industry"></i> -->
                    </div>
                    <h4 class="title">Industry</h4>
                    <h2>Technology</h2>
                </div>

                <div class="col-md-4 details1">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Technologies.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-laptop-code"></i> -->
                    </div>
                    <h4 class="title">Technologies</h4>
                    <h2>Angular, NodeXpress, Ionic, Mongo DB </h2>
                </div>
            </div>
        </div>

        <div class="col-md-2 mt-4"></div>
    </div>

    <!-- <div class="text-center mt-5">
</div> -->

    <div class="row mt-5 mb-5">
        <div class="col-md-1"></div>

        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="card border-0 block1">
                        <div class="detail-icon1">
                            <img src="assets/images/Case study/requirement.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="fas fa-clipboard"></i> -->
                        </div>
                        <h3 style="text-align: center;">Requirement</h3>
                        <p>
                            To develop a product that offers employees a digital workspace through automated sign-in, visitor management, and desk booking. The device has features including an iOS and Android-compatible employee smartphone app, QR visitor and employee sign-in, ID
                            badge printing, instant notifications, a digital agreement, and evacuation management. The organisation wants to digitise workspaces to keep a secure and healthy working environment.
                        </p>
                    </div>
                    <div class="card border-0 block2">
                        <div class="detail-icon2">
                            <img src="assets/images/Case study/challenges.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-lightbulb"></i> -->
                        </div>
                        <h3 style="text-align: center;">Challenges</h3>
                        <p>
                            This product had to be developed for three separate platforms: iOS, Android, and the web. We chose to use Angular Ionic instead of writing separate scripts for each platform because it would have been time-consuming and would need a number of developers.
                        </p>
                    </div>
                    <div class="card border-0 block3">
                        <div class="detail-icon3">
                            <img src="assets/images/Case study/result.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-thumbs-up"></i> -->
                        </div>
                        <h3 style="text-align: center;">Result</h3>
                        <p>
                            The product has been delivered, and we handled substantially all of the UI/UX, frontend, and backend. Additionally, we administer the cloud for this product. The software guarantees zero-touch sign-in, manages employee data, screens visitors and employees,
                            manages evacuations, prints ID badges, and sends immediate notifications to the business. Additionally, it offers a digital agreement function that enables users to provide the data needed by the company to construct a database
                            of individuals without having to complete any papers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
