import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { OnHoverDirective } from './shared/on-hover.directive';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { CompanyComponent } from './components/company/company.component';
import { JobComponent } from './job/job.component';
import { Router, RouterModule, Scroll } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplicationFormComponent } from './application-form/application-form.component';
import { CaseStudiesModule } from './case-studies/case-studies.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { MeetingComponent } from './contact-us/meeting/meeting.component';
import {MatInputModule} from '@angular/material/input';
import { ToastrModule, ToastrService } from 'ngx-toastr';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { WalkInComponent } from './walk-in/walk-in.component';
import { SsologinComponent } from './modal/ssologin/ssologin.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgOtpInputModule } from  'ng-otp-input';
import { AuthInterceptor } from './dashboard/interceptor/auth.interceptor';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    NavbarComponent,
    PageNotFoundComponent,
    OnHoverDirective,
    LoadingSpinnerComponent,
    CompanyComponent,
    JobComponent,
    ApplicationFormComponent,
    MeetingComponent,
    WalkInComponent,
    SsologinComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    FormsModule,
   SharedModule,
   CaseStudiesModule,
   HttpClientModule,
   RecaptchaModule,
   RecaptchaFormsModule,
   MatDatepickerModule,
   MatCardModule,
   MatNativeDateModule,
   NoopAnimationsModule,
   MatInputModule,
   NgOtpInputModule,
   ToastrModule.forRoot({
    maxOpened: 1,
    timeOut: 3000,
    preventDuplicates: true,
  }),

  ],
  exports:[
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 50]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        /* setTimeout is the core line to solve the solution */
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        })
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
 }
