import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technology-dvms',
  templateUrl: './technology-dvms.component.html',
  styleUrls: ['./technology-dvms.component.css']
})
export class TechnologyDvmsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
