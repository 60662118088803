import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Fingerprint2 from 'fingerprintjs2';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}
  token:any
  intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<unknown>> {
     console.log(JSON.parse(localStorage.getItem('otpResponse') as string))
    this.generateFingerprint();
    if (request.url.includes("api/v1/auth/authenticate-super") || (request.url.includes("api/v1/auth/validate")) || request.url.includes("/api/v1/auth/authenticate")) {
      console.log('inside if condition');
      this.token = '';
    }else{
       this.token = (JSON.parse(localStorage.getItem('otpResponse') as string).accessToken);
    }
    console.log(request.url,'ghbnjmk')
    const modifiedRequest = request.clone({
      headers: request.headers
      .
      append(
        'authorization',
         `Bearer ${this.token}`
      ).append('terminalId',`${JSON.parse(localStorage.getItem('UserDetail') as string)}`)
    });
    return handler.handle(modifiedRequest);
  }


   generateFingerprint() {
      Fingerprint2.get((components) => {
        const values = components.map((component) => component.value);
        const terminal = Fingerprint2.x64hash128(values.join(''), 31);
        localStorage.setItem('UserDetail',JSON.stringify(terminal))
      });
  }
}
