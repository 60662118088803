<!-- <section class="page-title abt-bg">
    <div class="col-lg-12 text-center">
        <div style="display: inline-block; vertical-align: super">
            <div class="title-branch-menu" style="vertical-align: super">
                Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
            </div>
        </div>
        <div style="display: inline-block">
            <h1 class="text-lg">Product development for a payment company</h1>
        </div>
    </div>
</section> -->
<section>
    <div>
        <img src="assets/images/Case study/Banking.png" alt="" style="position: relative;">
    </div>
</section>

<div class="container-fluid">
    <!-- <div class="row mb-5 mt-5">
      <div class="col-md-2"></div>
      <div class="col-md-10">
          <h4 class="text-secondary">Company</h4>
          <h1 class="mt-4 company-name">Hancom</h1>
      </div>
  </div> -->

    <div class="row mt-5">
        <div class="col-md-2 mt-4"></div>
        <div class="col-md-8 mt-4">
            <div class="row">
                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Region.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-map-marker-alt"></i> -->
                    </div>
                    <h4 class="title">Region</h4>
                    <h2>India</h2>
                </div>
                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Industry.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-industry"></i> -->
                    </div>
                    <h4 class="title">Industry</h4>
                    <h2>Banking</h2>
                </div>

                <div class="col-md-4 details1">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Technologies.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-laptop-code"></i> -->
                    </div>
                    <h4 class="title">Technologies</h4>
                    <h2>Angular, JavaScript, HTML, CSS</h2>
                </div>
            </div>
        </div>
        <div class="col-md-2 mt-4"></div>
    </div>
    <!-- <div class="text-center mt-5">
</div> -->
    <div class="row mt-5 mb-5">
        <div class="col-md-1"></div>

        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="card border-0 block1">
                        <div class="detail-icon1">
                            <img src="assets/images/Case study/requirement.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="fas fa-clipboard"></i> -->
                        </div>
                        <h3 style="text-align: center;">Requirement</h3>
                        <p>
                            The company's major goal was to integrate all of the different banking modules under one roof, which is why they sought to produce a single product that included all of the different modules. In addition, the company wanted to visualize all types of data
                            in a more appealing manner. The company had different uses for each module and was planning to introduce a product that would combine all of them in one spot. They also wanted a user-friendly application, which necessitated
                            strong UI/UX design.
                        </p>
                    </div>
                    <div class="card border-0 block2">
                        <div class="detail-icon2">
                            <img src="assets/images/Case study/challenges.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-lightbulb"></i> -->
                        </div>
                        <h3 style="text-align: center;">Challenges</h3>
                        <p>
                            We had to consider a number of factors to provide a positive user experience, including the fact that, despite the application's size, the loading time should be minimum, and the user should not have any problem utilizing it. Integrating all modules in
                            a user-specific approach to avoid overloading the user with superfluous data was challenging. We also included a feature that allows users to monitor the life cycle of their transactions; however, developing a module that can
                            track and document all types of transaction data was difficult.
                        </p>
                    </div>
                    <div class="card border-0 block3">
                        <div class="detail-icon3">
                            <img src="assets/images/Case study/result.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-thumbs-up"></i> -->
                        </div>
                        <h3 style="text-align: center;">Result</h3>
                        <p>
                            We developed a robust application in which the modules are split in such a manner that only the relevant module loads when the user uses it, resulting in a faster loading time. And, because users may access different modules depending on their user type,
                            the user experience has been enhanced. The user management is done extremely successfully since we assisted them in creating a digital platform where all of their modules are included in one application, and we also guaranteed
                            that the application's look and feel are appealing. We also incorporated a graphical representation of the data to make it more engaging.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
