import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SsologinComponent } from 'src/app/modal/ssologin/ssologin.component';
import { ContactFormService } from 'src/app/services/contact-form.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  logintosso='http://106.51.85.240'

  loginForm: FormGroup;
  showLoadingIndicator = false;


  constructor(
    public router:Router,
    public dialog: MatDialog,
    private service : ContactFormService,
    private toastr: ToastrService,
    private route: ActivatedRoute

     ) {
      this.loginForm = new FormGroup({
        emailId: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9._%+-]+@testyantra\.com|in$/),
          Validators.email,
        ]),
      })
      }

  ngOnInit(): void {
    console.log("yes called");

    // this.route.fragment.subscribe(f => {
    //   const element = document.querySelector("#" + f)
    //   if (element) element.scrollIntoView()
    // })
  }

  goToTop() {
    window.scroll(0, 0);
  }



  // show(link, dropdown) {
    // link.nativeElement.classList.push('show');
    // dropdown.nativeElement.classList.push('show');
    // if (link.className.includes('show')) {
    //   return;
    // }
    // console.log(link.className)
    // link.className += " show";
    // dropdown.className += " show";
    // dropdown.classList.push('show');
  // }




  // hide(link, dropdown) {
  //   console.log('mouse leave');
    // (link.className as string).replace("show", "");
  //   console.log((dropdown.className as string).split(' ').filter(a => a!=="show").join(" "));
  //   (dropdown.className as string).split(' ').filter(a => a!=="show").join(" ");
  //   (link.className as string).split(' ').filter(a => a!=="show").join(" ");
  //   console.log(link.className);
  // }

  openDialog(){
    const dialogRef= this.dialog.open(SsologinComponent,{
      width: '500px',
      height: '75%',
      panelClass:'ssologin',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'update') {
        // this.getUser();
      }
    });
  }


  submitbtn(event){
    this.showLoadingIndicator=true
     this.service.login(this.loginForm.value).subscribe(res=>{
      if (!res.error) {
        this.showLoadingIndicator=false
        this.toastr.success(res.message);
        localStorage.setItem('UserDetails',JSON.stringify(this.loginForm.value))
    const dialogRef= this.dialog.open(SsologinComponent,{
      width: '339px',
      height: '48%',
      panelClass:'ssologin'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'update') {
      }
    });
      }
     },(err) => {
      if (err.status) {
        this.showLoadingIndicator=false
        this.toastr.error(err.error.message);

      } else {
        this.toastr.error('CONNECTION_ERROR');
        this.showLoadingIndicator=false
      }
    });
  }


}
