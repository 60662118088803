<div class="img-fluid m-auto">
  <div style="display: flex">
    <img src="../../../assets/images/new-images/1-_4_.webp" alt="Company" style="position: relative" width="100%" />
  </div>
</div>
<!-- WHO WE ARE -->
<div class="section about bg-gray" id="who">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-1 col-md-12">
        <h3 style="text-align: center"></h3>
      </div>
    </div>
  </div>
  <div class="mycontainer">
    <p>
      TekPyramid began as a software service provider, initially specializing
      in Quality Assurance services as part of software development. We
      continued to grow in this direction, and over time, we were recognized by
      a wide range of domestic and international customers who were pleased with
      our QA services. We are well-known worldwide for providing high-quality
      testing services.
    </p>
    <p>
      In 2019, we considered diversifying TekPyramid's business without diluting
      the TekPyramid brand. This sparked the idea of launching a new brand
      called 'TekPyramid'. However, from a legal standpoint, TekPyramid is
      a division of TekPyramid that primarily provides development services.
    </p>
    <!-- <p>
      We also have been an integral part of IT skills development ecosystem and
      worked with Governments, Universities and Corporates to bridge the IT
      skills gap. We have transformed over 600,000 graduates into ‘IT Ready’ job
      seekers and are proud to say that over 70% of them have landed jobs in at
      large IT MNCs and also within TekPyramid.
    </p>
    <p>
      We were well known for our focus on product delivery and deployment, we
      draw from our experience over 13 years of having worked with Industry
      Leaders across diverse industries. This has enabled us to differentiate
      ourselves from providing tradiztional technology which has helped us to be
      recognize as a preferred workplace for those who are passionate about
      technology.
    </p> -->
  </div>
</div>
<!-- WHO WE ARE -->

<!-- About us  -->
<div class="section about" id="TekPyramidCompany">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <!-- <h3 style="text-align: center">ABOUT US</h3>
        <div class="mydivider1 mb-3"></div> -->
      </div>
    </div>
  </div>
  <div class="mycontainer">
    <div class="mydivider mb-4"></div>
    <h2 class="tagline text-center" style="letter-spacing: 0; font-size: 40px">
      About <span class="text-color">us</span>
    </h2>
    <p>
      Since its inception in 2019, TekPyramid has provided software
      development and related IT services. To deliver high-quality solutions, we
      combine proven methodologies, business domain knowledge, and technology
      expertise from 900+ skilled software professionals.
    </p>

    <p>
      We investigate each customer case thoroughly in order to understand the
      client's needs and objectives and to provide a dependable and efficient
      solution. We analyse all available options and provide clients with
      competent advice to help them make sound business decisions.
    </p>
  </div>
</div>
<!-- About us  -->

<div class="section about">
  <div class="col-lg-12 text-center">
    <div class="section-title">
      <div class="mydivider mb-4"></div>
      <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
        <span class="text-color">70+</span> CUSTOMERS FROM STARTUPS TO
        <span class="text-color">FORTUNE 500</span> ENTERPRISES
      </h2>
      <p>
        We deliver globally, providing result-driven project management and
        seamless communication.
      </p>
    </div>
  </div>
</div>
<br />

<!--  client logo -->

<!-- statistics -->
<div class="wow fadeIn animated" style="visibility: visible; animation-name: fadeIn">
  <div class="container" id="stats">
    <div class="row">
      <!-- counter -->
      <div
        class="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated containerz"
        data-wow-duration="300ms" style="
          visibility: visible;
          animation-duration: 300ms;
          animation-name: fadeInUp;
        ">
        <!-- <i class="fas fa-users medium-icon" style="color: orange"></i> -->
        <img src="../../../assets/images/company/Developers.png" class="img" />
        <span id="anim-number-pizza" class="counter-number"></span>
        <span class="timer counter alt-font appear" data-to="980" data-speed="7000">900+</span>
        <p class="counter-title">Developers</p>
      </div>
      <!-- end counter -->
      <!-- counter -->
      <div
        class="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated containerz1"
        data-wow-duration="600ms" style="
          visibility: visible;
          animation-duration: 600ms;
          animation-name: fadeInUp;
        ">
        <img src="../../../assets/images/company/Happy client.png" class="img" />
        <span class="timer counter alt-font appear" data-to="980" data-speed="7000">70+</span>
        <span class="counter-title">Happy Clients</span>
      </div>
      <!-- end counter -->
      <!-- counter -->
      <div
        class="col-md-3 col-sm-6 bottom-margin-small text-center counter-section wow fadeInUp xs-margin-bottom-ten animated containerz2"
        data-wow-duration="900ms" style="
          visibility: visible;
          animation-duration: 900ms;
          animation-name: fadeInUp;
        ">
        <img src="../../../assets/images/company/projects completed.png" class="img" />
        <span class="timer counter alt-font appear" data-to="810" data-speed="7000">60+</span>
        <span class="counter-title">Projects Completed</span>
      </div>
      <!-- end counter -->
      <!-- counter -->
      <div class="col-md-3 col-sm-6 text-center counter-section wow fadeInUp animated containerz3"
        data-wow-duration="1200ms" style="
          visibility: visible;
          animation-duration: 1200ms;
          animation-name: fadeInUp;
        ">
        <img src="../../../assets/images/company/technologies.png" class="img" />
        <span class="timer counter alt-font appear" data-to="600" data-speed="7000">40+</span>
        <span class="counter-title">Technologies</span>
      </div>
      <!-- end counter -->
    </div>
  </div>
</div>
<!-- statistics -->

<!-- Global presence start -->
<br />
<br />
<!-- <div>
  <div class="col-lg-12 text-center">
    <div class="section-title">
      <div class="mydivider mb-4"></div>
      <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
        <span class="text-color">CLIENTS & PARTNERS</span> IN
        <span class="text-color">6+</span> COUNTRIES
      </h2>
    </div>
  </div>
  <div class="mycontainer">
    <img src="assets/images/map\TekPyramid Business Deck (2).png" width="100%"
      style="position: relative; margin-top: 30px" alt="locations map" />
  </div>
</div> -->
<!-- Global presence end-->

<!-- why TekPyramid start -->
<div class="col-md-12 text-center" id="whyTekPyramid">
  <div class="section-title">
    <div class="mydivider mb-4"></div>
    <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
      <span class="text-color">why</span> TekPyramid?
    </h2>
    <!-- <p style="margin-top: 20px">
      We solve problems by providing solutions as true partners to our clients.
      <br />
      Our developers, strategists, and designers are self-sufficient, eloquent,
      collaborative, and enthusiastic about their work. <br />
      We purposefully kept our team small and our organisation flat in order to
      remain agile, credible, and human-centered.
    </p> -->
  </div>
</div>
<br />

<div class="container">
  <div class="row">
    <div class="col-md-4 Services-tab item">
      <div class="folded-corner service_tab_1">
        <div class="text">
          <img src="../../../assets/images/company/quality gaurantee.png" class="img" />
          <p class="item-title" style="color: orange"></p>
          <h3 style="color: orange">QUALITY GUARANTEE</h3>
          <p>
            <!-- /.item-title -->
          </p>
          <p class="text-justify">
            As a quality assurance partner to our clients, we never compromise
            on the quality of our services.
            <span *ngIf="QG">
              To ensure the elimination of defects in all of our processes.we
              use Six Sigma methodologies. We can keep our promises about
              quality, delivery, and cost because we have processes and
              standards in place.
            </span>
          </p>
          <div>
            <button class="btn readButton btn-sm" (click)="readMore('quarlity_guarantee')">
              {{ QG ? "Read Less" : "Read More" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 Services-tab item">
      <div class="folded-corner service_tab_1">
        <div class="text">
          <img src="../../../assets/images/company/technology up-to-date.png" class="img" />
          <p class="item-title"></p>
          <h3 style="color: orange">TECHNOLOGY IS UP TO DATE</h3>
          <p>
            <!-- /.item-title -->
          </p>
          <p class="text-justify">
            We never stop improving our services, whether it is through the
            exploration of new technologies,
            <span *ngIf="TU">engineering practices, management
              methodologies, and standards to improve our team capabilities and increase
              customer satisfaction. <br /><br />
            </span>
          </p>
          <div>
            <button class="btn readButton btn-sm" (click)="readMore('technology_up_to_date')">
              {{ TU ? "Read Less" : "Read More" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 Services-tab item">
      <div class="folded-corner service_tab_1">
        <div class="text">
          <img src="../../../assets/images/company/Automation accomplishment.png" class="img" />
          <p class="item-title"></p>
          <h3 style="color: orange">AUTOMATION ACCOMPLISHMENT</h3>
          <p></p>

          <p class="text-justify">
            Due to our expertise in a variety of automated tools and
            technologies, we are able to provide integrated,
            <span *ngIf="AA"> scalable, and
              robust solutions with the best cost-performance ratio. Our Tech Lab is constantly evaluating newer
              products on the
              market and can provide you with the best solution based on your
              requirements.
            </span>
          </p>
          <div>
            <button class="btn readButton btn-sm" (click)="readMore('automation_accomplishment')">
              {{ AA ? "Read Less" : "Read More" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-4 Services-tab item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <img src="../../../assets/images/company/customer centric approach.png" class="img" />
              <p class="item-title"></p>
              <h3 style="color: orange">CUSTOMER-CENTRIC APPROACH</h3>
              <p class="text-justify">
                We consider our customers as the heart of our business. We
                believe in providing the best solutions
                <span *ngIf="CA">to our clients while
                  remaining nimble in our delivery.We work with them to create and implement technology-enabled
                  business transformation initiatives. We recognize our clients'
                  needs and provide high-quality services on time, within
                  budget, and with a laser-like focus on quality. We foster an
                  environment in which communication is straightforward, and our
                  clients can reach us at their leisure, regardless of time
                  zone.
                </span>
              </p>
              <button class="btn readButton btn-sm" (click)="readMore('customer_centric')">
                {{ CA ? "Read Less" : "Read More" }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4 Services-tab item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <img src="../../../assets/images/company/Business experience.png" class="img" />
              <p class="item-title"></p>
              <h3 style="color: orange">BUSINESS EXPERIENCE EXPERTISE</h3>
              <p class="text-justify">
                TekPyramid has more than 4 years of experience providing
                professional services to clients
                <span *ngIf="BE">all over the world. We have
                  completed numerous projects of varying sizes.Complexity in a variety of business and technology
                  domains.
                  Because of our extensive business domain experience, we can
                  easily communicate with our customers and their business
                  needs. This knowledge base and experience allow us to save our
                  clients valuable time and effort while also delivering the
                  right solution for every problem.
                </span>
              </p>
              <button class="btn readButton btn-sm" (click)="readMore('businessExperence')">
                {{ BE ? "Read Less" : "Read More" }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4 Services-tab item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <img src="../../../assets/images/company/solutions.png" class="img" />
              <p class="item-title"></p>
              <h3 style="color: orange">
                One Stop Solution for your Business.
              </h3>
              <p class="text-justify">
                TekPyramid is a dynamic solutions provider excelling in the
                design, development, and software applications.
                <span *ngIf="SB"> We are a
                  one-stop solution for all your needs
                </span>
              </p>
              <button class="btn readButton btn-sm" (click)="readMore('solutionForBusiness')">
                {{ SB ? "Read Less" : "Read More" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 offset-md-2">
      <div class="row">
        <div class="col-md-6 col-sm-12 Services-tab item">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <img src="../../../assets/images/company/organize.png" class="img" />
              <p class="item-title"></p>
              <h3 style="color: orange">We are Organized.</h3>
              <p class="text-justify">
                With routinely scheduled meetings, smart workflow management,
                regular client interactions,
                <span *ngIf="WO">advanced QA, prompt bug testing &
                  deployments of functioning prototypes, and ROI updates, we are
                  always ready to give a flawless working Experience.
                </span>
              </p>
              <button class="btn readButton btn-sm" (click)="readMore('weOrganised')">
                {{ WO ? "Read Less" : "Read More" }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 Services-tab item" id="alg">
          <div class="folded-corner service_tab_1">
            <div class="text">
              <img src="../../../assets/images/company/hands.png" class="img" />
              <p class="item-title"></p>
              <h3 style="color: orange">We are adaptable and trustworthy.</h3>
              <p class="text-justify">
                We are methodical and careful about how much work we take on,
                and we always Ensure that

                <span *ngIf="WT">we have room for more work. We are
                  built to constantly deliver and are scalable.
                </span>
              </p>
              <button class="btn readButton btn-sm" (click)="readMore('weAdaptable')">
                {{ WT ? "Read Less" : "Read More" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- why TekPyramid end  -->
  <!-- our vision start-->
  <div class="col-md-12 text-center" id="TekPyramidVision">
    <div class="section-title">
      <div class="mydivider mb-4"></div>
      <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
        We bring your <span class="text-color"> Vision to Reality </span>
      </h2>
    </div>
  </div>
  <div class="section about" id="">
    <div class="col text-center">
      <div class="section-title">
        <p style="margin-top: 20px; font-weight: bold; font-size: 17px">
          As a team of enthusiasts in user experience, design, and development
          It brings us great oy to shape your vision into reality.
        </p>
      </div>
    </div>
  </div>
  <!-- our vision end -->
  <!-- our approach start -->
  <div class="col-md-12 text-center" id="TekPyramidApproach">
    <div class="section-title">
      <div class="mydivider mb-4"></div>
      <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
        Our <span class="text-color">Approach</span>
      </h2>
    </div>
  </div>
  <div class="section about" id="">
    <div class="col text-center">
      <div class="section-title">
        <h2 class="tagline" style="letter-spacing: 0; font-size: 28px">
          <span class="text-color">900+</span> ENGINEERS FULLY AT YOUR
          <span class="text-color">SERVICE</span>
        </h2>
        <p style="margin-top: 20px">
          Our people are what set us apart – whether you need the best expert
          with niche knowledge or a team with diversified technology skills and
          domain expertise, TekPyramid has a pool of over 900+ IT experts to
          call on.
        </p>
      </div>
    </div>

    <!-- <div class="flip">
            <div class="maincontainer1 col ">
                <div class="back ">
                    <h2></h2>
                    <p>
                        Every design and development problem begins with genuine, business-centric answers to the question "Why?" We articulate those objectives and then apply our expertise to find the sweet spot between realistic and impactful. <br />
                        <br />
                        <span style="color: orange">WHAT’S HAPPENING:</span> – Meetings on discovery
                        <br> Design + technology audits
                        <br> Stakeholder collaboration
                        <br> Setting benchmarks and SMART goals

                    </p>
                </div>
                <div class="front ">
                    <div class="image">
                        <img class="imagev" src="./assets/images/our approach/1.png " /> -->
    <!-- <h2 style="
                  font-size: 20px;
                  color: white;
                  margin-top: 70px;
                  background-color: orange;
                  width: 260px
                ">
                            WHY HAVE WE ALL COME TOGETHER?..
                        </h2> -->
    <!-- </div>
                </div>
            </div> -->

    <!-- <div class="maincontainer1 col"> -->
    <!-- <div class="back ">
                    <h2></h2>
                    <p>
                        After goals come strategy. We collaborate with you to co-create this by delving into your user stories and data and challenging any assumptions. The team, the plan, and the timeline are then resourced and built out. We keep the scope sane and flexible
                        to remain agile and responsive.
                        <br /> <br>
                        <span style="color: orange; text-align: center">WHAT’S HAPPENING:</span
              >
              Identifying the target audience
              – Scope definition
              – Concept development
              – Project management + DevOps
              – Team resourcing + onboarding

            </p>
          </div> -->
    <!-- <div class="front ">
            <div class="image ">
              <img class="imagev" src="./assets/images/our approach/2.png" /> -->
    <!-- <h2
                style="
                  font-size: 20px;
                  color: white;
                  margin-top: 100px;
                  background-color: orange;
                  width: 260px;
                "
              >
                WHAT WILL IT TAKE?
              </h2> -->
    <!-- </div>
          </div>
        </div>
        <div class="maincontainer1 col">
          <div class="back ">
            <h2></h2>
            <p>
              This is what many clients believe is the first step — what they hired us to do from the start.
              We sprint from working prototype to minimum viable product to production-ready release,
               concentrating on the components that add the most value and have the greatest impact.
              <br /> <br>
              <span style="color: orange">WHAT’S HAPPENING:</span> Prioritization + Road Mapping Design Sprints Early Validation and Testing Proof of concept → MVP(Minimum Viable Product) → Production
                    </p>
                </div>
                <div class="front ">
                    <div class="image">
                        <img class="imagev" src="./assets/images/our approach/3.png" /> -->
    <!-- <h2 style="
                  font-size: 20px;
                  color: white;
                  margin-top: 100px;
                  background-color: orange;
                  width: 260px;
                ">
                            We build
                        </h2> -->
    <!-- </div>
                </div>
            </div>

            <div class="maincontainer1 col">
                <div class="back ">
                    <h2></h2>
                    <p>
                        We're in the data with you, measuring, interpreting, and customizing new initiatives to continue the transition we've started. It's no surprise that we're wondering not just what's next, but why.
                        <br /> <br>
                        <span style="color: orange">WHAT’S HAPPENING:</span> Stakeholder onboarding and training Data analytics User experience research Iteration Optimization

                    </p>
                </div>
                <div class="front ">
                    <div class="image ">
                        <img class="imagev" src="./assets/images/our approach/4.png" /> -->
    <!-- <h2 style="
                  font-size: 20px;
                  color: white;
                  margin-top: 70px;
                  background-color: orange;
                  width: 260px;
                ">
                            WE SEE WHAT WORKS, AND DO MORE OF IT.
                        </h2> -->
    <!-- </div>
                </div>
            </div>
        </div> -->

    <div class="row">
      <div class="col-sm-12 col-md-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="./assets/images/our approach/1.png" alt="Avatar" class="approachCard" />
            </div>
            <div class="flip-card-back">
              <p>
                Every design and development problem begins with genuine,
                business-centric answers to the question "Why?" We articulate
                those objectives and then apply our expertise to find the sweet
                spot between realistic and impactful.
                <br />
                <br />
                <span style="color: orange">WHAT’S HAPPENING:</span> Meetings on
                discovery Design + technology audits Stakeholder collaboration
                Setting benchmarks and SMART goals
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="./assets/images/our approach/2.png" alt="Avatar" class="approachCard" />
            </div>
            <div class="flip-card-back">
              <p>
                After goals come strategy. We collaborate with you to co-create
                this by delving into your user stories and data and challenging
                any assumptions. The team, the plan, and the timeline are then
                resourced and built out. We keep the scope sane and flexible to
                remain agile and responsive.
                <br /><br />
                <span style="color: orange; text-align: center">WHAT’S HAPPENING:</span>
                Identifying the target audience – Scope definition – Concept
                development – Project management + DevOps – Team resourcing +
                onboarding
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="./assets/images/our approach/3.png" alt="Avatar" class="approachCard" />
            </div>
            <div class="flip-card-back">
              <p>
                This is what many clients believe is the first step — what they
                hired us to do from the start. We sprint from working prototype
                to minimum viable product to production-ready release,
                concentrating on the components that add the most value and have
                the greatest impact.
                <br /><br />
                <span style="color: orange">WHAT’S HAPPENING:</span>
                Prioritization + Road Mapping Design Sprints Early Validation
                and Testing Proof of concept → MVP(Minimum Viable Product) →
                Production
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <img src="./assets/images/our approach/4.png" alt="Avatar" class="approachCard" />
            </div>
            <div class="flip-card-back">
              <p>
                We're in the data with you, measuring, interpreting, and
                customizing new initiatives to continue the transition we've
                started. It's no surprise that we're wondering not just what's
                next, but why.
                <br /><br />
                <span style="color: orange">WHAT’S HAPPENING:</span> Stakeholder
                onboarding and training Data analytics User experience research
                Iteration Optimization
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- our approach end -->

  <!-- our journey start -->
  <!-- <div class="col text-center" id="TekPyramidJourney">
    <div class="section-title">
      <div class="mydivider mb-4"></div>
      <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
        OUR <span class="text-color">journey </span>
      </h2>
    </div>
    <div class="timeline">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="timeline-container">
              <div class="timeline-end">
                <p>2007-19</p>
              </div>
              <div class="timeline-continue">
                <div class="row timeline-left">
                  <div class="col-md-6 d-md-none d-block">
                    <p class="timeline-date"></p>
                  </div>
                  <div class="col-md-6">
                    <div class="timeline-box">
                      <div class="timeline-icon d-md-none d-block">
                        <i class="fa fa-cogs"></i>
                      </div>
                      <div class="timeline-text">
                        <ul>
                          <li>
                            TekPyramid began as a software service provider,
                            initially specializing in Quality Assurance services
                            as part of software development. We continued to
                            grow in this direction, and over time, we were
                            recognized by a wide range of domestic and
                            international customers who were pleased with our QA
                            services. We are well-known worldwide for providing
                            high-quality testing services.
                          </li>
                          <li>
                            We honed our skills in a wide range of testing
                            areas, including manual testing, automation testing,
                            API testing, and performance testing. We also tested
                            for HADR and Sustained Resilience in the
                            environment. We knew everything there was to know
                            about testing. We created resources, competencies,
                            and capabilities within TekPyramid.
                          </li>
                        </ul>
                      </div>
                      <div class="timeline-icon d-md-block d-none">
                        <i class="fa fa-cogs"></i>
                      </div>
                    </div>
                    <div class="arrow-box"></div>
                  </div>
                  <div class="col-md-6 d-md-block d-none">
                    <p class="timeline-date"></p>
                  </div>
                </div>

                <div class="timeline-end">
                  <p>2019-20</p>
                </div>
                <div class="row timeline-right">
                  <div class="col-md-6">
                    <p class="timeline-date"></p>
                  </div>
                  <div class="col-md-6">
                    <div class="timeline-box">
                      <div class="timeline-icon d-block">
                        <i class="fa fa-cogs"></i>
                      </div>
                      <div class="timeline-text">
                        <ul>
                          <li>
                            TekPyramid was established as a development unit
                            of TekPyramid. Begun with eight projects and
                            created the first virtual pool for clients.
                          </li>
                          <li>
                            Customers from SMB's to Fortune 500 companies
                            collaborated with us with complete confidence.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="arrow-box1"></div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="timeline-year">
                      <p>2020-21</p>
                    </div>
                  </div>
                </div>

                <div class="row timeline-left">
                  <div class="col-md-6 d-md-none d-block">
                    <p class="timeline-date"></p>
                  </div>
                  <div class="col-md-6">
                    <div class="timeline-box">
                      <div class="timeline-icon d-md-none d-block">
                        <i class="fa fa-cogs"></i>
                      </div>
                      <div class="timeline-text">
                        <ul>
                          <li>
                            Despite the COVID-19 pandemic, our team expanded to
                            more than 100 employees, and no one was laid off.
                          </li>
                          <li>
                            With the help of our dedicated and determined
                            employees, we could serve 20+ customers in four
                            different domains.
                          </li>
                        </ul>
                      </div>
                      <div class="timeline-icon d-md-block d-none">
                        <i class="fa fa-cogs"></i>
                      </div>
                    </div>
                  </div>
                  <div class="arrow-box1"></div>
                  <div class="col-md-6 d-md-block d-none">
                    <p class="timeline-date"></p>
                  </div>
                </div>
                <div class="row timeline-right">
                  <div class="col-md-6"></div>
                  <div class="timeline">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="timeline-container">
                            <div class="timeline-end">
                              <p>2021-22</p>
                            </div>
                            <div class="row timeline-right">
                              <div class="col-md-6">
                                <p class="timeline-date"></p>
                              </div>
                              <div class="col-md-6">
                                <div class="timeline-box">
                                  <div class="timeline-icon d-block">
                                    <i class="fa fa-cogs"></i>
                                  </div>
                                  <div class="timeline-text">
                                    <ul>
                                      <li>
                                        We are proud to announce that within a
                                        matter of 3 years we could serve more
                                        tham 40+ customers.
                                      </li>
                                      <li>
                                        we are working on 50+ projects,
                                        established our presence in 8+ domains
                                        and our team size has grown immensely.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="arrow-box1"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
<!-- why TekPyramid end -->

<!----------- our brand start-------------->

<!-- <div class="col text-center" id="TekPyramidBrands">
  <div class="section-title">
    <div class="mydivider mb-4"></div>
    <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
      OUR <span class="text-color">brands </span>
    </h2>
  </div>
  <div class="mycontainer">
    <p style="margin-top: 30px">
      TekPyramid Global has grown into a global conglomerate with different
      brands under its umbrella, offering a full suite of exceptional IT
      services to its clients. Similar to TekPyramid, TekPyramid created
      multiple brands like QSpiders, JSpiders, and SkillRary. We also started
      with product development initiatives, the result of which is our very own
      product Flinko.
    </p>
  </div>
  <div class="mycontainer">
    <div class="row">
      <div class="col-md-4 Services-tab item">
        <div class="folded-corners service_tab_1">
          <div class="text">
            <p class="item-title" style="color: orange"></p>
            <div class="brands">
              <a class="navbar-brand" href="https://TekPyramidglobal.com/services/software-testing/" target="_blank">
                <img src="assets/images/our-brands/TekPyramid.png" class="imgTekPyramid" />
              </a>
            </div>
            <div class="line"></div>
            <b>TekPyramid </b>is one of the largest independent verification
            and validation companies, offering software testing services to
            clients to achieve their ‘Quality Goals’ quickly and effectively.
            <div style="margin-top: 30px">
              <a href="https://TekPyramidglobal.com/services/software-testing/" target="_blank" class="clickHere">Click
                here</a>
              to know more about our software testing services.
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 Services-tab item">
        <div class="folded-corners service_tab_1">
          <div class="text">
            <p class="item-title" style="color: orange"></p>
            <div class="brands">
              <a class="navbar-brand" href="https://www.skillrary.com/" target="_blank">
                <img src="assets/images/our-brands/SkillRary.png" class="imgSkillray" />
              </a>
            </div>
            <div class="line"></div>
            <b>Skillrary</b> is one of our niche products. It is a comprehensive
            edtech platform offering learning and assessment features to our
            clients. From corporates to colleges, Skillrary offers an exclusive
            ready to use and customizable platform.
            <div style="margin-top: 10px">
              <a href="https://www.skillrary.com/" target="_blank" class="clickHere">
                Click here</a>
              to know more about our learning and assessment platform.
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 Services-tab item">
        <div class="folded-corners service_tab_1">
          <div class="text">
            <p class="item-title" style="color: orange"></p>
            <div class="brands">
              <a class="navbar-brand" href="https://www.qspiders.com/" target="_blank">
                <img src="assets/images/our-brands/QSpiders.png" class="img" />
              </a>
            </div>
            <div class="line"></div>
            <b>QSpiders</b> Training Institute is the world’s premier IT
            Finishing School. Training IT skills to bridge the gap between the
            education and business world, QSpiders has successfully helped more
            than 3000 companies globally to identify the IT talent.
            <div style="margin-top: 30px">
              <a href="https://www.qspiders.com/" target="_blank" class="clickHere">Click here</a>
              to know more about our training wing.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-4 offset-md-4 Services-tab item">
        <div class="folded-corners service_tab_1">
          <div class="text">
            <p class="item-title" style="color: orange"></p>
            <div class="brands">
              <a class="navbar-brand" href="https://jspiders.com/" target="_blank">
                <img src="assets/images/our-brands/JSpiders.png" class="imgJSpiders" />
              </a>
            </div>
            <div class="line"></div>
            <b>JSpiders</b> is the world’s ace Java development training
            organization with an aim to bridge the gap between the demands of
            the industry and the curriculum of educational institutions.
            Training Institute is the world’s premier IT Finishing School.
            Multinational companies hire from us, and the list is still growing.
            JSpiders has successfully helped more than 4000 companies globally
            to identify the IT talent.
            <div style="margin-top: 10px">
              <a href="https://jspiders.com/" target="_blank" class="clickHere">Click here</a>
              to know more about our training wing.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- our brand end-->

<!-- how we do it -->
<div class="col-lg-16 text-center">
  <div class="section-title">
    <div class="mydivider mb-4"></div>
    <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
      how we <span class="text-color">do </span> It?
    </h2>
  </div>
</div>
<div class="mycontainer">
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-md-6">
        <div class="box1">
          <div class="our-services settings">
            <div class="d-flex align-items-center justify-content-center">
              <img class="iconImage" src="../../../assets/images/Company page/scale.png" />
              <img class="iconImage1" src="../../../assets/images/Company page/scale (1).png" />
            </div>
            <h4 class="howWeDoHeading">Scale</h4>
            <p>
              We have a pool of experts with a wide range of technological and
              domain backgrounds and qualification levels, as well as on-demand
              resources, who provide full-cycle implementation services and can
              take on projects at any stage.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="box">
          <div class="our-services settings">
            <div class="d-flex align-items-center justify-content-center">
              <img class="iconImage" src="../../../assets/images/Company page/experience.png" />
              <img class="iconImage1" src="../../../assets/images/Company page/experience (1).png" />
            </div>

            <h4 class="howWeDoHeading">Expertise</h4>
            <p>
              Over the course of more than four years on the market,
              TekPyramid has completed 60+ projects, gaining extensive
              technical and industry-specific knowledge, as well as
              participating in partnership programmes with industry leaders.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="box">
          <div class="our-services settings">
            <div class="d-flex align-items-center justify-content-center">
              <img class="iconImage" src="../../../assets/images/Company page/transparency.png" />
              <img class="iconImage1" src="../../../assets/images/Company page/transparency (1).png" />
            </div>

            <h4 class="howWeDoHeading">Transparency</h4>
            <p>
              We ensure collaboration transparency by assigning certified
              project managers to each project, providing regular reporting,
              demos, and granting clients access to the test stand and project
              tracking tools.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="box">
          <div class="our-services settings">
            <div class="d-flex align-items-center justify-content-center">
              <img class="iconImage" src="../../../assets/images/Company page/flexibility.png" />
              <img class="iconImage1" src="../../../assets/images/Company page/flexibility (1).png" />
            </div>

            <h4 class="howWeDoHeading">Flexibility</h4>
            <p>
              We tailor engagement scenarios and partnership models to our
              customers' needs. Instead of a blended rate, we provide an optimal
              project staffing model by engaging system architects for on-demand
              high-level consultations while providing a proportional mix of
              middle and senior engineers based on task complexity.
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 col-lg-6">
                <div class="box1">
                    <div class="our-services settings">
                        <div class="icon">
                            <img class="iconImage" src="../../../assets/images/Company page/scale.png" />
                        </div>

                        <h4 class="howWeDoHeading">Scale</h4>
                        <p>
                            We have a pool of experts with a wide range of technological and domain backgrounds and qualification levels, as well as on-demand resources, who provide full-cycle implementation services and can take on projects at any stage.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="box">
                    <div class="our-services settings">
                        <div class="icon">
                            <img class="iconImage" src="../../../assets/images/Company page/experience.png" />
                        </div>

                        <h4 class="howWeDoHeading">Expertise</h4>
                        <p>
                            Over the course of more than four years on the market, TekPyramid has completed 50+ projects, gaining extensive technical and industry-specific knowledge, as well as participating in partnership programmes with industry leaders such as Clairvoyant,
                            Allstate, Amdocs, PayNext, and Visa.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="box">
                    <div class="our-services settings">
                        <div class="icon">
                            <img class="iconImage" src="../../../assets/images/Company page/transparency.png" />
                        </div>

                        <h4 class="howWeDoHeading">Transparency</h4>
                        <p>
                            We ensure collaboration transparency by assigning certified project managers to each project, providing regular reporting, demos, and granting clients access to the test stand and project tracking tools.
                        </p>
                    </div>
                </div>
            </div> -->
      <!-- <div class="col-sm-12 col-md-6 offset-md-0 col-lg-4 offset-lg-4">
                <div class="row"> -->
      <!-- <div class="col-md-12 col-sm-12">
                        <div class="box">
                            <div class="our-services settings">
                                <div class="icon">
                                    <img class="iconImage" src="../../../assets/images/Company page/flexibility.png" />
                                </div>

                                <h4 class="howWeDoHeading">Flexibility</h4>
                                <p>
                                    We tailor engagement scenarios and partnership models to our customers' needs. Instead of a blended rate, we provide an optimal project staffing model by engaging system architects for on-demand high-level consultations while providing a proportional
                                    mix of middle and senior engineers based on task complexity.
                                </p>
                            </div>
                        </div>
                    </div> -->
      <!-- <div class="col-md-4">
          <div class="box">
              <div class="our-services ssl">
                  <div class="icon"> <img src="https://i.imgur.com/v6OnUqu.png"> </div>
                  <h4>SSL secured</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
          </div>
      </div> -->
      <!-- <div class="col-md-4">
          <div class="box">
              <div class="our-services database">
                  <div class="icon"> <img src="https://i.imgur.com/VzjZw9M.png"> </div>
                  <h4>Database</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
          </div>
      </div> -->
      <!-- </div>
            </div> -->
    </div>
  </div>
</div>
<!-- how we do it -->

<!-- our team -->
<!-- <div class="section" id="TekPyramidTeam">
  <div class="col-lg-16 text-center">
    <div class="section-title">
      <div class="mydivider mb-4"></div>
      <h2 class="tagline" style="letter-spacing: 0; font-size: 40px">
        OUR <span class="text-color">LEADERSHIP </span> TEAM
      </h2>
    </div>
    <div class="team-grid">
      <div class="container">
        <div class="row people d-flex justify-content-center">
          <div class="col-md-4 col-lg-3 item">
            <div class="box">
              <div>
                <img src="assets/images/team/Girish.png" alt="Girish Ramanna" />
              </div>
              <div class="cover">
                <div class="social">
                  <a href="https://in.linkedin.com/in/girish-ramanna-a015a48"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </div>
              </div>
            </div>
            <h3 class="name mt-3">Girish Ramanna</h3>
            <p class="title">Founder & CEO</p>
          </div>

          <div class="col-md-4 col-lg-3 item">
            <div class="box">
              <div>
                <img
                  src="../../../assets/images/team/praveen.png"
                  alt="Praveen Dyamappa"
                />
              </div>
              <div class="cover">
                <div class="social" id="tweet">
                  <a href="https://in.linkedin.com/in/praveen-dyamappa-01567b82"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </div>
              </div>
            </div>
            <h3 class="name mt-3">Praveen Dyamappa</h3>
            <p class="title">VP OF ENGINEERING</p>
          </div>
          <div class="col-md-4 col-lg-3 item">
            <div class="box">
              <div>
                <img
                  src="assets/images/team/Madhan.jpg"
                  alt="Madhan Shamachar"
                />
              </div>
              <div class="cover">
                <div class="social">
                  <a href="https://in.linkedin.com/in/madan-shamachar-70b53658"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </div>
              </div>
            </div>
            <h3 class="name mt-3">Madan Shamachar</h3>
            <p class="title">VP OF BUSINESS & OPERATIONS</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- our team -->