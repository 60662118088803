<!-- <section class="page-title abt-bg">
    <div class="col-lg-12 text-center">
        <div style="display: inline-block; vertical-align: super">
            <div class="title-branch-menu" style="vertical-align: super">
                Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
            </div>
        </div>
        <div style="display: inline-block">
            <h1 class="text-lg">Dental Clinic Management Solution</h1>
        </div>
    </div>
</section> -->
<section>
    <div>
        <img src="assets/images/Case study/Healthcare.png" alt="" style="position: relative;">
    </div>
</section>

<!-- Client Details -->
<!-- <section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Client Details
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <table class="table table-bordered table-hover">
      <tbody>
        <tr>
          <td>Client</td>
          <td>
            An Health Care Organization that uses an interactive application for their customers
          </td>
        </tr>
        <tr>
          <td>Client Requirement</td>
          <td>
            To modify the existing application to provide the option to their customers for having a conference whenever
            it is needed.
          </td>
        </tr>
        <tr>
          <td>Client Location</td>
          <td>
            Cincinnati, Ohio, USA
          </td>
        </tr>
        <tr>
          <td>Executed From</td>
          <td>Bangalore</td>
        </tr>
        <tr>
          <td>Team Size</td>
          <td>6</td>
        </tr>
        <tr>
          <td>Engagement Model</td>
          <td>T & M</td>
        </tr>
        <tr>
          <td>Technologies</td>
          <td>
            Java, Hibernate, Google Guava & Oracle PL/SQL
          </td>
        </tr>
        <tr>
          <td>Methodology</td>
          <td>Agile - Scrum</td>
        </tr>
        <tr>
          <td>Repository</td>
          <td>Git with GitLab</td>
        </tr>
      </tbody>
    </table>
  </div>
</section> -->
<!-- Client Details -->

<!-- Client Challenges -->
<!-- <section class="section bg-gray">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Client Challenges
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <p>
      The client was looking for a clickable prototype which opens up the patient’s personal information, the medical
      history, current condition etc.
    </p>
    <p>
      Breaking out the design into smaller projects like collection of information from the customer, addressing his/her
      needs and giving a solution and integrating them to achieve the final goal was the challenge.
    </p>
  </div>
</section> -->
<!-- Client Challenges -->

<!-- TekPyramid solution -->
<!-- <section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          TekPyramid solution
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <p>
      Initially we had few rounds of discussion with the client to understand their existing application. We also came
      up with few suggestions which, we felt ,may add to their goal.
    </p>
    <p>
      We designed the screens with Angular JS and also took care of the usability and design aspect of the web pages.
    </p>
  </div>
</section> -->
<!-- Benefits and Results -->
<div class="container-fluid">

    <div class="row">
        <div class="col-md-2 mt-4"></div>

        <div class="col-md-8 mt-4">
            <div class="row">
                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Region.png" alt="" style="position: relative; max-width: 30% !important;">
                    </div>
                    <h4 class="title">Region</h4>
                    <h2>United Kingdom</h2>
                </div>

                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Industry.png" alt="" style="position: relative; max-width: 30% !important;">
                    </div>
                    <h4 class="title">Industry</h4>
                    <h2>Healthcare</h2>
                </div>

                <div class="col-md-4 details1">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Technologies.png" alt="" style="position: relative; max-width: 30% !important;">
                    </div>
                    <h4 class="title">Technologies</h4>
                    <h2>Angular, Node.js, Express.js, MySQL</h2>
                </div>
            </div>
        </div>

        <div class="col-md-2 mt-4"></div>
    </div>



    <div class="row mt-5 mb-5">

        <div class="col-md-1"></div>

        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="card border-0 block1">
                        <div class="detail-icon1">
                            <img src="assets/images/Case study/requirement.png" alt="" style="position: relative; max-width: 8% !important;">
                        </div>
                        <h3 style="text-align: center;">Requirement</h3>
                        <p>
                            The company had an existing web application that worked fine and allowed them to talk about their work, but they wanted to refactor and improve it. As there were a number of drawbacks, such as the user experience being unfriendly, the UI being unappealing,
                            the application's loading time being lengthy, and the code used being inefficient, all of this resulted in poor performance and a negative user experience. As a result, the company decided to redesign the entire web application.
                        </p>
                    </div>
                    <div class="card border-0 block2">
                        <div class="detail-icon2">
                            <img src="assets/images/Case study/challenges.png" alt="" style="position: relative; max-width: 8% !important;">
                        </div>
                        <h3 style="text-align: center;">Challenges</h3>
                        <p>
                            The application was slow, the app's size and loading time appeared to be enormous, and everything was done in a single module. Despite being an Angular Application, the majority of the code was written in JQuery and JavaScript. Because JQuery is considered
                            an outdated framework, the entire code had to be refactored. 90% of the JQuery code was converted to pure Angular and TypeScript code.
                        </p>
                    </div>
                    <div class="card border-0 block3">
                        <div class="detail-icon3">
                            <img src="assets/images/Case study/result.png" alt="" style="position: relative; max-width: 8% !important;">
                        </div>
                        <h3 style="text-align: center;">Result</h3>
                        <p>
                            All existing issues were resolved as we revamped the application. The frameworks used to write optimized code also improved efficiency. We divided the application into modules to improve its capability and usability. We assisted the company in developing
                            the website as well as the admin panel. All HR compliances, as well as an appointment booking feature, were included in the admin panel. Users saved a lot of internet time because the entire website was refactored, and the
                            application's performance improved significantly. We also improved the User Experience, and the web application is now more aesthetically appealing.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-1"></div>

        </div>
    </div>
