<!-- <section class="page-title abt-bg">
    <div class="col-lg-12 text-center">
        <div style="display: inline-block; vertical-align: super">
            <div class="title-branch-menu" style="vertical-align: super">
                Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
            </div>
        </div>
        <div style="display: inline-block">
            <h1 class="text-lg">Multi-vendors ECommerce application</h1>
        </div>
    </div>
</section> -->
<section>
    <div>
        <img src="assets/images/Case study/Ecommerce.png" alt="" style="position: relative;">
    </div>
</section>

<div class="container-fluid">
    <!-- <div class="row mb-5 mt-5">
        <div class="col-md-2"></div>
        <div class="col-md-10">
            <h4 class="text-secondary">Company</h4>
            <h1 class="mt-4 company-name">Mr. & Mrs. Cart</h1>
        </div>
    </div> -->

    <div class="row mt-5">
        <div class="col-md-2 mt-4"></div>

        <div class="col-md-8 mt-4">
            <div class="row">
                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Region.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-map-marker-alt"></i> -->
                    </div>
                    <h4 class="title">Region</h4>
                    <h2>India</h2>
                </div>

                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Industry.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-industry"></i> -->
                    </div>
                    <h4 class="title">Industry</h4>
                    <h2>E-Commerce</h2>
                </div>

                <div class="col-md-4 details1">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Technologies.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-laptop-code"></i> -->
                    </div>
                    <h4 class="title">Technologies</h4>
                    <h2>Java, React, React Native, MySQL.</h2>
                </div>
            </div>
        </div>

        <div class="col-md-2 mt-4"></div>
    </div>

    <!-- <div class="text-center mt-5">
  </div> -->

    <div class="row mt-5 mb-5">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="card border-0 block1">
                        <div class="detail-icon1">
                            <img src="assets/images/Case study/requirement.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="fas fa-clipboard"></i> -->
                        </div>
                        <h3 style="text-align: center;">Requirement</h3>
                        <p>
                            The company's goal is to provide a multi-vendor platform where products can be sold at lower prices compared to the existing competitors in the market. Their aim is to provide locals with the opportunity to sell their products with high-profit margins
                            and good audience reach. It promotes the idea of having multiple suppliers so that people can load their products from all over India. To make this happen, they needed a web application and a mobile application, however, they
                            contacted us to develop the same.
                        </p>
                    </div>
                    <div class="card border-0 block2">
                        <div class="detail-icon2">
                            <img src="assets/images/Case study/challenges.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-lightbulb"></i> -->
                        </div>
                        <h3 style="text-align: center;">Challenges</h3>
                        <p>
                            The main challenge was combining various integration services because the company did not have its own logistics management or payment gateway, however, we had to combine all available 3rd parties such as Indian postal services, Delhivery, Axis bank,
                            Razorpay, and a few others. We needed to provide all of these services on a single platform. Integration with third-party services was difficult because everyone had their own way of working, so we needed to understand their
                            technical implementations. Technical challenges included integrating their services into our application while keeping the client's requirements in mind.
                        </p>
                    </div>
                    <div class="card border-0 block3">
                        <div class="detail-icon3">
                            <img src="assets/images/Case study/result.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-thumbs-up"></i> -->
                        </div>
                        <h3 style="text-align: center;">Result</h3>
                        <p>
                            Users can easily gain access to a lower-cost E-Commerce application. The Users registered as resellers can set up their own store, add products, and set their own profit margins.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-1"></div>
        </div>
    </div>
