<!-- <section class="page-title abt-bg">
  <div class="col-lg-12 text-center">
    <div style="display: inline-block; vertical-align: super">
      <div class="title-branch-menu" style=" vertical-align: super">
        Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
      </div>
    </div>
    <div style="display: inline-block;">
      <h1 class="text-lg">
        DEVOPS
      </h1>
    </div>
  </div>
</section> -->

<!-- Test Automation and Performance Testing for DevOps Platform -->

<section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Test Automation and Performance Testing for DevOps Platform
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <p>
      Customer is a leading IT behemoth with its presence across the globe.
    </p>
    <p>
      The platform is designed with six independent Pods that interact with each other. The Pods work like a federation
      of empowered business units. This design supports Pod-specific digital market places and specialized ecosystem
      partners for each Pod. This helps in maintaining an unbroken and continuous delivery model, based on the in-built
      Point of Delivery (PoD) approach.
    </p>
    <p>
      The overall system architecture is as illustrated:
    </p>
    <img src="../../../assets/images/Case study/devops.png" style="position: relative; width: 100%;" alt="" class="img-fluid">
  </div>
</section>
<!-- Test Automation and Performance Testing for DevOps Platform -->

<!-- Solution Approach -->
<section class="section bg-gray">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Solution Approach
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <ul>
      <li>
        The areas in scope were UI and the web services that needed to be automated
      </li>
      <li>
        BDD Approach was required and all features and user stories would be provided by the business owners
      </li>
      <li>
        Single automation platform was setup to automate both UI and the web services to better manage reporting and tracking across all pods
      </li>
      <li>
        More than 600 User stories automation on both UI and API
      </li>
      <li>
        DevOps cultural integration of infrastructure was also automated to spin up and down instances for test execution as and when required
      </li>
      <li>
        Performance Testing was planned using JMeter for 10,000 users by utilizing cloud infra for load generation
      </li>
      <li>
        Results analysed against benchmarks and possible remediation steps were proposed to business owner
      </li>
    </ul>
  </div>
</section>
<!-- Solution Approach -->

<!-- Benefits -->
<section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">Benefits</h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <ul>
      <li>
        Single framework and reporting provided better data management
      </li>
      <li>
        Tests run in DevOps pipeline with zero downtime
      </li>
      <li>
        BDD Approach to better integrate with Business Teams
      </li>
      <li>
        Modifications and New Test Automation takes lesser time due to BDD
      </li>
    </ul>
  </div>
</section>
<!-- Benefits -->
