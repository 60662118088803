<section class="page-title abt-bg">
  <div class="col-lg-12 text-center">
    <div style="display: inline-block; vertical-align: super">
      <div class="title-branch-menu" style=" vertical-align: super">
        Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
      </div>
    </div>
    <div style="display: inline-block;">
      <h1 class="text-lg">
        Salesforce Testing
      </h1>
    </div>
  </div>
</section>

<!-- Introduction -->
<section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Introduction
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <p>
      Customer is a Leading provider of protection solution with the dominant global presence.
    </p>
    <p>
      The customer was reviewing options to setup a single CRM (Salesforce lightning) across the company to maintain
      global businesses. The architecture grew complex due to consolidation of business units across the globe. Hence,
      Multiple vendors were involved in the development of the architecture. Need to identify and establish well defined
      QA process and governance for quality improvements was of paramount importance.
    </p>
  </div>
</section>
<!-- Introduction -->

<!-- Solution Approach -->
<section class="section bg-gray">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Solution Approach
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ul>
          <li>
            Different types of test cases were identified and prepared to cover functional, integration, user role and
            system level requirements
          </li>
          <li>
            Regression test suite were built to make sure the platform was stable due to frequent changes in the
            application.
          </li>
          <li>
            Automatable test cases were identified to build automation suite
          </li>
          <li>
            The toolset included for this engagement are:
            <ul style="list-style: disc;">
              <li>
                Java and Selenium
              </li>
              <li>
                Cucumber, Maven, Test NG
              </li>
              <li>
                Jenkins CI, GitHub
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <img src="../../../assets/images/Case study/salesforce-solution.png" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</section>
<!-- Solution Approach -->

<!-- Benefits -->
<section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">Benefits</h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <ul>
      <li>
        Significant (5x) reduction in overall testing effort per cycle
      </li>
      <li>
        Significant reduction in defect density because of requirement review process
      </li>
      <li>
        Solution designed to manage Test data Creation as well as test case execution
      </li>
      <li>
        Framework built to automate multi users, multi flow & multi parameters
      </li>
    </ul>
  </div>
</section>
<!-- Benefits -->
