<footer class="footer-pd">
  <div class="footer-container">
    <div class="row">
      <div class="col-lg-3 col-md-6 mb-lg-0">
        <div class="footerdiv mb-3">
          <a class="navbar-brand" href="/">
            <img
              src="../../../assets/new-images/logo.png"
              class="img" alt="logo"/>
          </a>
        </div>

        <p class="footer-description1" style="
            text-align: justify !important;
            text-justify: distribute !important;
            text-align-last: left !important;
          ">
          TekPyramid is a rapidly expanding software development company. We
          offer a comprehensive range of high-quality software development and consulting
          services.
        </p>

        <div class="head mt-5">
          <h4 class="footer-heading mb-1 letter-spacing text-uppercase mt-4">
            Head office
          </h4>
          <p class="footer-descrip">
            TekPyramid, 92/9, 3rd floor, Gopalan Promenade Mall, Above Smart Bazaar, 80 Feet Road, Kathriguppe Main Rd, Banashankari 3rd Stage, Bengaluru, Karnataka 560085.
          </p>
          <p class="footer-description2">+91 9632940869</p>
          <p class="footer-description2">contactus@tekpyramid.com</p>
          <p class="footer-description2">&copy; 2024 tekpyramid</p>
        </div>
        <!--  Headoffice end-->
      </div>

      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-9">
            <div class="row">
              <!-- our service start-->
              <div class="col-lg-7 col-md-6 mb-sm-3 mb-md-5 mb-lg-0 pl-5">
                <h4 class="footer-heading">Our Solutions</h4>
                <ul class="list-unstyled footer-menu lh-30 mt-sm-2">
                  <li>
                    <a routerLink="/development/software-development">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Software Development
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/mobile-app-development">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Mobile App Development
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/web-solution">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Web Solution
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/cloud-solution">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Cloud Solution
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/migration-and-re-engineering">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Migration & Re-engineering
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/middleware-integration">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Middleware Integration
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/eCommerce">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      E-Commerce Development
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/full-stack-development">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Full Stack Development
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/datascience">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Data Science
                    </a>
                  </li>
                  <li>
                    <a routerLink="/development/it-consulting">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      IT Consulting
                    </a>
                  </li>
                </ul>
              </div>
              <!-- solution end-->

              <!--Our Services-->
              <div class="col-lg-5 col-md-6 mb-sm-3 mb-md-5 mb-lg-0 pl-5">
                <h4 class="footer-heading">Our Services</h4>
                <ul class="list-unstyled footer-menu lh-30 mt-2">
                  <li>
                    <a routerLink="/specialized-qa/managed-service">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Managed Services
                    </a>
                  </li>
                  <li>
                    <a routerLink="/specialized-qa/professional-services">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Professional Services
                    </a>
                  </li>
                  <li>
                    <a routerLink="/specialized-qa/support-services">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Support Services
                    </a>
                  </li>
                  <li>
                    <a routerLink="specialized-qa/industry-4.0">
                      <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                      Industry 4.0
                    </a>
                  </li>
                </ul>
              </div>
              <!--  Our Services  end-->
            </div>
          </div>

          <!--  Industries start-->
          <div class="col-lg-3 col-md-6 mb-sm-3 mb-md-2 mb-lg-0 pl-5">
            <h4 class="footer-heading">Our Domains</h4>
            <ul class="list-unstyled footer-menu lh-30 mt-2">
              <li>
                <a routerLink="/industries/banking">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  Banking
                </a>
              </li>
              <li>
                <a routerLink="/industries/insurance">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  Insurance
                </a>
              </li>
              <li>
                <a routerLink="/industries/retail-ecommerce">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  E-Commerce
                </a>
              </li>
              <li>
                <a routerLink="/industries/retail">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  Retail
                </a>
              </li>
              <li>
                <a routerLink="/industries/health-care">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  Healthcare
                </a>
              </li>
              <li>
                <a routerLink="industries/education">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  Education
                </a>
              </li>
              <li>
                <a routerLink="/industries/telecom">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  Telecom
                </a>
              </li>
              <li>
                <a routerLink="/strategic-qa/technology">
                  <i class="fa fa-angle-double-right mr-2 fnt-size-14"></i>
                  Technology
                </a>
              </li>
            </ul>
          </div>
          <!--  Industries end-->
        </div>
      </div>
      <!-- <div class="social">
        <ul class="mb-0 footer-socials">
          <li class="list-no">
            <a href="https://www.facebook.com/technoelevate/" target="_blank">
              <i
                class="fab fa-facebook-square icon-font"
                aria-hidden="true"
              ></i>
            </a>
          </li>
          <li class="list-no"></li>
          <li class="list-no">
            <a
              href="https://instagram.com/technoelevate?utm_medium=copy_link"
              target="_blank"
            >
              <i class="fa fa-instagram icon-font" aria-hidden="true"></i>
            </a>
          </li>
          <li class="list-no">
            <a
              href="https://in.linkedin.com/company/technoelevate"
              target="_blank"
            >
              <i class="fa fa-linkedin icon-font" aria-hidden="true"></i>
            </a>
          </li>
          <li class="list-no">
            <a
              href="https://twitter.com/technoelevate?t=v2SNxdrYA2wWom6ifSjDLA&s=09"
              target="_blank"
            >
              <i class="fa fa-twitter icon-font" aria-hidden="true"></i>
            </a>
          </li>
          <li class="list-no">
            <a href="https://goo.gl/maps/WrT28L8E4V1d9J8q6" target="_blank">
              <i
                class="fa fa-map-marker fa-2xl icon-font"
                aria-hidden="true"
              ></i>
            </a>
          </li>
        </ul>
      </div> -->
    </div>

  </div>
</footer>