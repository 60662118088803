  <div class="text-center  d-flex justify-content-center align-items-center" *ngIf='showLoadingIndicator'>
                                <img src="../../assets/images//Spinner-2.svg" alt="Spinner" class="spinner">
                            </div>

<div class="flip-card" >
  <!-- <div class="flip-card"  [ngClass]="isSelected? 'isflip': ''"> -->
  <!-- <div class="front">
    <form [formGroup]="loginForm" >
    <div class="logo">
      <img src="../../../assets/images/footer-logo.png" alt="" class="w-60">
    </div>
    <div class="title mt-5">
      <h1>Login</h1>
    </div>
    <div class="input-boxes mt-5">
      <div class="input-box input-field mt-4 flex-column">
          <i class="fa fa-envelope float-start" aria-hidden="true"></i>

          <mat-form-field appearance="outline" class="logininput">
              <input matInput type="text" placeholder="Enter Your Email" formControlName="email" style="padding:0px 30px;" autocomplete="off" >
          </mat-form-field>

             <mat-error *ngIf="this.loginForm.get('email')?.invalid && this.loginForm.get('email')?.touched">
              <p>   Email address must include testyantra.in or testyantra.com</p>
             </mat-error>
      </div>
      <div class="button input-box justify-content-center mt-4">
         <button type="submit" class="btn submitbtn"  [disabled]="loginForm.invalid"   (click)="submitbtn($event)">Login</button>
      </div>
  </div>
</form>
  </div> -->
  <div class="back">
    <form [formGroup]="otpForm" (ngSubmit)='onSubmit()'>
      <div>
        <h1>Enter your OTP here</h1>
      </div>
        <div class="mt-4">
          <ng-otp-input  (onInputChange)="onOtpChange($event)" #ngOtpInput  [config]="config"></ng-otp-input>
        </div>
        <div>

            <div class="d-flex align-items-center justify-content-between mt-5">
              <div class="text-center fs-5">
                <button type="button" class="btn resend pl-0" (click)="resendOtp()">
                  <div>
                    <h5 class="mb-0 mr-2">Resend OTP</h5>
                  </div>
                  <div>
                    <i class="fa fa-refresh" id="refresh"  aria-hidden="true"></i>
                  </div>
                 </button>
            </div>
              <div>
                <button class="btn submitbtn" [disabled]="otpForm.get('otp')?.value?.length!== 6" type="submit">Submit</button>
              </div>


            </div>

        </div>
      </form>
  </div>
</div>
