<section class="page-title abt-bg">
  <div class="col-lg-12 text-center">
    <div style="display: inline-block; vertical-align: super">
      <div class="title-branch-menu" style=" vertical-align: super">
        Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
      </div>
    </div>
    <div style="display: inline-block;">
      <h1 class="text-lg">
        Telecom
      </h1>
    </div>
  </div>
</section>

<!-- Regression Test Suite Automation for Telecom Service Provider -->
<section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Regression Test Suite Automation for Telecom Service Provider
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <p>
      Our Customer is one of Top 3 provider of Telecommunications Services in India. They are also leaders in
      implementing 4G Services for approximately 223 million subscribers.
    </p>
    <p>
      Customer’s Siebel CRM Implementation was designed to cover Sales, Service and Marketing processes of the
      organization and to enable customer centricity and support the CRM strategy of the organization. Some of the key
      aspects were:
    </p>
    <ul>
      <li>
        The implementation covered Direct Sales, Contact Centre (Customer Care), Order Management, Partner Portal,
        Mobile Number Portability, Marketing Automation & Analytics
      </li>
      <li>
        260 Steel Thread flows were defined to Secure the delivery for Siebel application on the basis of criticality,
        complexity and volumetric on production application.
      </li>
    </ul>
  </div>
</section>
<!-- Regression Test Suite Automation for Telecom Service Provider -->

<!-- Solution Approach -->
<section class="section bg-gray">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Solution Approach
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
  </div>
  <div class="mycontainer">
    <div class="row ">
      <div class="col col-12 col-md-6">
        <p>
          TekPyramid proposed a home grown, open source, highly customizable and manageable Automation Framework to
          handle the challenges in the environment.
        </p>
        <ul>
          <li>
            High Priority Test cases identified and road map for automation was aligned with
            impending release cycle.
          </li>
          <li>
            Framework developed in Hybrid design to avail benefits of best practices
          </li>
          <li>
            Optimal Re-usable methods/libraries created with industry coding standards to
            handle all environments seamlessly with zero modifications at code level
          </li>
          <li>
            JSCH (Java Secured channel) is used to handle proxy setting
          </li>
          <li>
            Customizable Reports generated which provides information for all levels –
            Graphs, debug logs, screenshots, etc
          </li>
          <li>
            Jenkins CI integrated to the framework and Lights Off Mode Execution scheduled
          </li>
          <li>
            Jenkins Master/Slave Setup on Local Machines for Parallel Executions
          </li>
        </ul>
      </div>
      <div class="col col-12 col-md-6">
        <img src="../../../assets/images/Case study/telecom-solution-approach.png" alt="" class="img-fluid" style="width:100%;">
      </div>
    </div>
  </div>
</section>
<!-- Solution Approach -->

<!-- Benefits and ResultsT -->
<section class="section">
  <div class="mycontainer">
    <div class="row">
      <div class="col col-12 col-md-12">
        <h3 style="text-align: center">
          Benefits and Results
        </h3>
        <div class="mydivider1 mb-3"></div>
      </div>
    </div>
    <ul>
      <li>
        Reduction in over 864-person hours per circle execution
      </li>
      <li>
        50+ undiscovered defects captured through automation
      </li>
      <li>
        Lights-off mode execution handled through Jenkins to utilize time away from desk effectively
      </li>
      <li>
        Application Differences (Performance, Functional, Configuration and Environmental) on
        various circles are captured easily through execution results
      </li>
      <li>
        Automation results published to all relevant stake holders automatically
      </li>
      <li>
        Repetitive execution helped client in capturing early defects without any manual efforts
      </li>
    </ul>
  </div>
</section>
<!-- Benefits and Results -->
