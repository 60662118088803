<div class="text-center  d-flex justify-content-center align-items-center" *ngIf='showLoadingIndicator'>
  <img src="../../assets/images//Spinner-2.svg" alt="Spinner" class="spinner">
</div>

<form  [formGroup]="loginForm">

<nav class="navbar navbar-expand-md navbar-light fixed-top">
  <div class="navbar-brand">
    <a class="navbar-brand" routerLink="/">
      <img
        src="../../../assets/new-images/logo.png"
        class="logo"
        style="margin-left: 22px"
    /></a>
  </div>




  <button class="navbar-toggler collapsed" type="button" data-target="#main-nav-1" data-toggle="collapse"
    aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>


  <div class="collapse navbar-collapse" id="main-nav-1">
    <!-- <ul class="navbar-nav ml-auto" >
      <li class="nav-item dropdown temp nav-item-top apply-li" style="margin-right: 70px;">
        <div class="applybtn">
          <button class="btn btn-dark btn-sm px-3 apply-button " routerLink="/contact/meeting">Book a Meeting</button>
      </div>
      </li>
    </ul> -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown temp nav-item-top navlink">
        <a data-toggle="dropdown" id="navbarDropdownMenuLink" #aboutLink aria-haspopup="true" aria-expanded="false"
          [ngClass]="
            router.url.includes('about-us') ? 'nav-link-active' : 'nav-link'
          " class="nav-link dropdown-toggle">COMPANY</a>
        <ul class="dropdown-menu comp" aria-labelledby="navbarDropdownMenuLink">
          <li class="nav-item">
            <a routerLink="/about-us" data-toggle="collapse" data-target=".navbar-collapse.show" fragment="TekPyramidCompany"
              class="dropdown-item">
              About Us
            </a>
          </li>
          <!-- <li class="nav-item">
            <a routerLink="/about-us" fragment="who" class="dropdown-item">
              Who We Are
            </a>
          </li> -->
          <li class="nav-item">
            <a routerLink="/about-us" fragment="whyTekPyramid" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Why TekPyramid
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/about-us" fragment="TekPyramidApproach" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Our Approach
            </a>
          </li>
          <!-- <li class="nav-item">
            <a routerLink="/about-us" fragment="TekPyramidJourney" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Our Journey
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/about-us" fragment="TekPyramidBrands" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Our Brands
            </a>
          </li> -->
          <!-- <li class="nav-item">
            <a routerLink="/about-us" fragment="TekPyramidTeam" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Our Team
            </a>
          </li> -->
        </ul>
      </li>

      <!-- services start -->
      <li class="nav-item dropdown temp2 nav-item-top navlink">
        <a data-toggle="dropdown" [ngClass]="
            router.url.includes('specialized-qa')
              ? 'nav-link-active'
              : 'nav-link'
          " #development aria-haspopup="true" aria-expanded="false" class="dropdown-toggle nav-link">SERVICES</a>
        <ul class="dropdown-menu comp2" #aboutList>
          <li class="nav-item">
            <a routerLink="/specialized-qa/managed-service" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">Managed Services</a>
          </li>
          <li class="nav-item">
            <a routerLink="/specialized-qa/professional-services" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Professional Services
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/specialized-qa/support-services" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Support Services
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/specialized-qa/industry-4.0" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Industry 4.0
            </a>
          </li>
        </ul>
      </li>
      <!-- services end -->
      <!--
solution start -->
      <li class="nav-item dropdown temp1 nav-item-top navlink">
        <a data-toggle="dropdown" [ngClass]="
            router.url.includes('development') ? 'nav-link-active' : 'nav-link'
          " #development aria-haspopup="true" aria-expanded="false" class="dropdown-toggle nav-link">SOLUTIONS</a>
        <ul class="dropdown-menu comp1" #aboutList>
          <li class="nav-item dropdown temp9">
            <a href="#" data-toggle="dropdown" [ngClass]="
                router.url.includes('development')
                  ? 'nav-link-active'
                  : 'nav-link'
              " #development aria-haspopup="true" aria-expanded="false"
              class="dropdown-item development-link nav-link ml-3">Development</a>
            <ul class="dropdown-menu subdiv comp9" onclick="closedroup()">
              <li class="nav-item">
                <a routerLink="/development/software-development" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">Software Development</a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/mobile-app-development" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">Mobile App Development</a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/web-solution" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  Web Solution</a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/cloud-solution" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">Cloud Solution</a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/migration-and-re-engineering" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  Migration & Re-engineering</a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/middleware-integration" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  Middleware Integration
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/eCommerce" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  E-Commerce Development
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/full-stack-development" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  Full Stack Development
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/development/datascience" class="dropdown-item" data-toggle="collapse"
                  data-target=".navbar-collapse.show">
                  Data Science
                </a>
              </li>
            </ul>
          </li>
          <!-- <li class="nav-item">
                        <a routerLink="/development/software-development" class="dropdown-item">Software Development </a>
                    </li> -->

          <li class="nav-item">
            <a routerLink="/development/it-consulting" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              IT Consulting
            </a>
          </li>
        </ul>
      </li>

      <!-- industries start -->
      <li class="nav-item dropdown temp3 nav-item-top navlink">
        <a data-toggle="dropdown" [ngClass]="
            router.url.includes('industries') ? 'nav-link-active' : 'nav-link'
          " aria-haspopup="true" aria-expanded="false" class="dropdown-toggle nav-link">
          INDUSTRIES
        </a>
        <ul class="dropdown-menu comp3">
          <li class="nav-item">
            <a routerLink="/industries/banking" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Banking</a>
          </li>
          <li class="nav-item">
            <a routerLink="/industries/insurance" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">Insurance
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/industries/retail-ecommerce" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              E-Commerce
            </a>
          </li>
          <!-- <li class="nav-item">
            <a routerLink="404" class="dropdown-item">
              Health Care
            </a>
          </li> -->
          <li class="nav-item">
            <a routerLink="/industries/retail" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">
              Retail</a>
          </li>
          <li class="nav-item">
            <a routerLink="/industries/health-care" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">Healthcare</a>
          </li>
          <li class="nav-item">
            <a routerLink="/industries/education" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">Education</a>
          </li>
          <li class="nav-item">
            <a routerLink="/industries/telecom" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">Telecom
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/strategic-qa/technology/" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">Technology</a>
          </li>
        </ul>
      </li>
      <!-- industries end -->
      <li class="nav-item dropdown nav-item-top navlink">
        <a routerLink="/contact/global-presence" data-toggle="collapse" data-target=".navbar-collapse.show" [ngClass]="
            router.url.includes('contact') ? 'nav-link-active' : 'nav-link'
          " class="nav-link">
          CONTACT US
        </a>
      </li>
      <li class="nav-item dropdown temp4 nav-item-top navlink">
        <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle nav-link" [ngClass]="
            router.url.includes('careers') ? 'nav-link-active' : 'nav-link'
          ">
          CAREERS
        </a>
        <ul class="dropdown-menu dropdown-menu-right comp4">
          <li class="nav-item">
            <a routerLink="/careers" fragment="job" class="dropdown-item" data-toggle="collapse"
              data-target=".navbar-collapse.show">Job Opportunities</a>
            <a routerLink="/careers" class="dropdown-item" fragment="Upskill Programme" data-toggle="collapse"
              data-target=".navbar-collapse.show">Upskill Programme</a>
          </li>
        </ul>
      </li>
      <!-- <li class="nav-item dropdown nav-item-top">

        <button class="btn login"> <a  [href]="logintosso" class="nav-link p-0" target="_blank">LOGIN </a></button>

      </li> -->
      <li class="nav-item dropdown temp4 nav-item-top">
        <!-- <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ariaDisabled]="loginForm.invalid" class="dropdown-toggle nav-link" [ngClass]="
            router.url.includes('careers') ? 'nav-link-active' : 'nav-link'
          ">
         <span>
          LOGIN
          <i class="fa fa-sign-out" aria-hidden="true"></i>
         </span> -->

         <!-- <button type="submit" class="btn submitbtn mt-1" matTooltip="Login"  [disabled]="loginForm.invalid" (click)="submitbtn($event)" >
          <i class="fa fa-sign-out" aria-hidden="true" style="font-size: 20px;"></i>
         </button> -->

        <!-- </a> -->
      </li>
    </ul>
  </div>
</nav>
<a class="go-to-top" (click)="goToTop()">
  <i class="fas fa-arrow-circle-up" style="font-size: 40px"></i>
</a>
</form>
