import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactFormService } from '../services/contact-form.service';

@Component({
  selector: 'app-walk-in',
  templateUrl: './walk-in.component.html',
  styleUrls: ['./walk-in.component.css']
})
export class WalkInComponent implements OnInit {
  walkInForm:FormGroup
  isSubmit=false
  showLoadingIndicator=false;
  tosterMessage: any;
  toster = false;
  file: File;
  drivenames: any;

  constructor(private fb:FormBuilder, private walkInService:ContactFormService) { }

  ngOnInit(): void {
    this.walkInForm =this.fb.group({
      fullName:['',[Validators.required]],
      email:['',[Validators.required,Validators.pattern('[a-zA-Z0-9+_.-.]+@+[a-zA-Z0-9]+[.]+[a-z]{2,4}')]],
      mobileNumber:['',[Validators.required,Validators.pattern('^[6-9][0-9]{9}$')]],
      alternativeNumber:['',],
      yearsOfExp:['',[Validators.required,Validators.min(0),Validators.max(100)]],
      ugEducationDetail:['',[Validators.required]],
      ugStream:['',[Validators.required]],
      ugPassOutYear:['',[Validators.required,Validators.maxLength(4)]],
      ugAggregate:['',[Validators.required,Validators.min(0),Validators.max(100)]],
      pgEducationDetail:['',],
      pgStream:['',],
      pgPassOutYear:['',[Validators.maxLength(4)]],
      pgAggregate:['',[Validators.min(0),Validators.max(100)]],
      skills:['',[Validators.required]],
      source:[false,],
      panIndia:['',[Validators.required]],
      driveName:['',]

    })
    this.getDrive()
  }



  onSubmit(walkInForm:FormGroup){
    const postData ={...walkInForm.value,source: walkInForm.get('source').value?'Yes':'No'};
    console.log(postData);
    this.isSubmit = true;
    if(this.walkInForm.valid){
      this.walkInService.postWalkInForm(postData).subscribe((res)=>{
        this.toster = true;
        this.isSubmit = false;
        this.tosterMessage = res.message
        this.walkInForm.reset();
        setTimeout(() => {
          this.toster = false;
          this.showLoadingIndicator = false;
        }, 3000);
        this.showLoadingIndicator = false;
      },
      err => {
        this.toster = true;
        this.isSubmit = false;
        this.tosterMessage =err.error.message
        this.walkInForm.reset();
        setTimeout(() => {
          this.toster = false;
          this.showLoadingIndicator = false;
        }, 3000);
        this.showLoadingIndicator = false;
      })
    }
  }

  getDrive(){
    this.walkInService.getDriveName().subscribe((res)=>{
       this.drivenames = res.data
       console.log(res);
    })
  }
  onUploadingCandidate(event:any){
    this.file = (event.target as HTMLInputElement).files[0];
  }

  // onUploadingCaseStudies(event: Event) {
  //   this.file = (event.target as HTMLInputElement).files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(this.file);
  //   reader.onload = () => {
  //     this.pdf = reader.result
  //   };
  // }

  keyPressAlphanumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
   ValidateAlpha(event)
  {
      var keyCode = (event.which) ? event.which : event.keyCode
      if ((keyCode < 65 || keyCode > 90) && (keyCode < 97 || keyCode > 123) && keyCode != 32)

      return false;
          return true;
  }


   isNumber(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}
}

