
<section class="section">
  <div class="p-3">
      <h2 class="text-center"> REGISTER FOR WALKIN DRIVE</h2>
      <div class="mydivider1 mb-3"></div>
  </div>
  <div class="container-fluid">
      <div class="row">
          <div class="col-12 offset-0 col-sm-8 offset-sm-2 col-md-12 offset-md-0 col-lg-8 offset-lg-2">
              <div class="card application-container">
                  <div class="row">
                      <div class="col-md-6">
                          <img class="application-img" src="../../assets/images/new-images/Application submission section.png" alt="">
                      </div>
                      <div class="col-md-6">
                          <form action="" [formGroup]="walkInForm" >
                            <div class="p-3">
                              <h3 class="text-center">ADD CANDIDATE MANUALLY :</h3>
                              <div class="mydivider1 mb-3"></div>
                          </div>
                              <!-- <div class="form-group">
                                  <label for="">BULK UPLOAD <sup class="text-danger">*</sup></label><br>
                                  <input type="file" class="file-upload-button" style="width: 100%;" accept="application/pdf,application/vnd.ms-excel" formControlName="file" (change)="onUploadingCandidate($event)" aria-label="Text input with radio button">
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.file.touched) && walkInForm.controls.file.errors?.required"><small>Upload Resume is Mandatory</small></div>
                              </div>
                             <div><button type="button"  class="btn btn-sm btn-dark">Upload Excel <i class="fa fa-download" aria-hidden="true"></i></button>
                            <label class="pl-2">Download Template</label></div> -->
                              <div class="form-group">
                                  <label for="">Full name <sup class="text-danger">*</sup></label>
                                  <input type="text" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.fullName.touched) && walkInForm.controls.fullName.errors }" class="form-control" formControlName="fullName" aria-label="Text input with radio button" (keypress)="ValidateAlpha($event)">
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.fullName.touched) && walkInForm.controls.fullName.errors?.required"><small>Full name is Mandatory</small></div>
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.fullName.touched) && walkInForm.controls.fullName.errors?.pattern"><small>Provide Valid Name(Only Alphabets are allowed)</small></div>
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.fullName.touched) && walkInForm.controls.fullName.errors?.maxlength"><small>Only 50 characters allowed.</small></div>
                              </div>
                              <div class="form-group">
                                  <label type="email" for="">Email <sup class="text-danger">*</sup></label>
                                  <input type="email" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.email.touched) && walkInForm.controls.email.errors }" class="form-control" formControlName="email" aria-label="Text input with radio button">
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.email.touched)&& walkInForm.controls.email.errors?.required"><small>Email is Mandatory</small></div>
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.email.touched) && walkInForm.controls.email.errors?.pattern"><small>Provide Valid Email</small></div>
                              </div>
                              <div class="form-group">
                                  <label for="">Contact Number<sup class="text-danger">*</sup></label>
                                  <input type="tel" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.mobileNumber.touched) && walkInForm.controls.mobileNumber.errors }" class="form-control" formControlName="mobileNumber" (keypress)="keyPressNumbers($event)" aria-label="Text input with radio button">
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.mobileNumber.touched) && walkInForm.controls.mobileNumber.errors?.required"><small>Contact Number is Mandatory</small></div>
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.mobileNumber.touched) && walkInForm.controls.mobileNumber.errors?.pattern"><small>Provide Valid Contact Number </small></div>
                              </div>
                              <div class="form-group">
                                  <label for="">Alternative Contact Number</label>
                                  <input type="tel" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.alternativeNumber.touched) && walkInForm.controls.alternativeNumber.errors }" class="form-control" formControlName="alternativeNumber" (keypress)="keyPressNumbers($event)" aria-label="Text input with radio button">
                                  <!-- <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.alternativeNumber.touched) && walkInForm.controls.alternativeNumber.errors?.required"><small>Alternative Contact Number is Mandatory</small></div> -->
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.alternativeNumber.touched) && walkInForm.controls.alternativeNumber.errors?.pattern"><small>Provide Valid Alternative Contact Number</small></div>
                              </div>
                              <div class="form-group">
                                <label for="">Year Of Experience</label>
                                <input type="text" class="form-control" formControlName="yearsOfExp"  [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.yearsOfExp.touched) && walkInForm.controls.yearsOfExp.errors }" (keypress)="keyPressNumbers($event)">
                                <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.yearsOfExp.touched) && walkInForm.controls.yearsOfExp.errors?.required"><small>Year Of Experience is Mandatory</small></div>
                                <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.yearsOfExp.touched) && walkInForm.controls.yearsOfExp.errors?.maxlength "><small>Provide Vaild Year Of Experience</small></div>
                              </div>
                              <div class="p-3">
                                <h3 class="text-center">EDUCATION DETAILS :</h3>
                                <div class="mydivider1 mb-3"></div>
                            </div>
                            <div class="form-group">
                              <label for="">UG Education Details <sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" formControlName="ugEducationDetail" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.ugEducationDetail.touched) && walkInForm.controls.ugEducationDetail.errors }" (keypress)="ValidateAlpha($event)">
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.ugEducationDetail.touched) && walkInForm.controls.ugEducationDetail.errors?.required"><small>UG Education Detailes is Mandatory</small></div>
                            </div>
                            <div class="form-group">
                              <label for="">Stream <sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" formControlName="ugStream" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.ugStream.touched) && walkInForm.controls.ugStream.errors }" (keypress)="ValidateAlpha($event)">
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.ugStream.touched) && walkInForm.controls.ugStream.errors?.required"><small>Stream is Mandatory</small></div>
                            </div>
                            <div class="form-group">
                              <label for="">Passout Year<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" formControlName="ugPassOutYear" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.ugPassOutYear.touched) && walkInForm.controls.ugPassOutYear.errors }" (keypress)="keyPressNumbers($event)">
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.ugPassOutYear.touched) && walkInForm.controls.ugPassOutYear.errors?.required"><small>UG PassoutYear is Mandatory</small></div>
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.ugPassOutYear.touched) && walkInForm.controls.ugPassOutYear.errors?.maxlength"><small>Provide Vaild Year</small></div>
                            </div>
                            <div class="form-group">
                              <label for="">Aggregate (%)<sup class="text-danger">*</sup></label>
                              <input type="text" class="form-control" formControlName="ugAggregate" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.ugAggregate.touched) && walkInForm.controls.ugAggregate.errors }" min="0" max="100" (keypress)="keyPressNumbers($event)">
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.ugAggregate.touched) && walkInForm.controls.ugAggregate.errors?.required"><small>UG Aggregate is Mandatory</small></div>
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.ugAggregate.touched) && walkInForm.controls.ugAggregate.errors?.max"><small>Provide Valid Aggregate(%)</small></div>
                            </div>
                            <div class="mydivider1 mb-3"></div>
                            <div class="form-group">
                              <label for="">PG Eduaction Details</label>
                              <input type="text" class="form-control" formControlName="pgEducationDetail" (keypress)="ValidateAlpha($event)">
                            </div>
                            <div class="form-group">
                              <label for="">Stream
                                <b class="text-danger" *ngIf="(walkInForm.controls.pgEducationDetail.value.length > 0 && walkInForm.controls.pgEducationDetail.value != ' ')">*</b>
                              </label>

                              <input type="text" class="form-control" formControlName="pgStream" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.pgStream.touched) && walkInForm.controls.pgStream.errors }"  (keypress)="ValidateAlpha($event)">
                              <div class="text-danger" *ngIf="(walkInForm.controls.pgEducationDetail.value.length > 0 && walkInForm.controls.pgEducationDetail.value != ' ')">
                                <div *ngIf="(isSubmit || walkInForm.controls.pgStream.touched && !walkInForm.controls.pgStream.value)"> Stream is required</div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="">Passout Year
                                <b class="text-danger" *ngIf="(walkInForm.controls.pgEducationDetail.value.length > 0 && walkInForm.controls.pgEducationDetail.value != ' ')">*</b>
                              </label>
                              <input type="text" class="form-control" formControlName="pgPassOutYear" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.pgPassOutYear.touched) && walkInForm.controls.pgPassOutYear.errors }" (keypress)="keyPressNumbers($event)">
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.pgPassOutYear.touched) && walkInForm.controls.pgPassOutYear.errors?.maxlength"><small>Provide Vaild Year</small></div>
                              <div class="text-danger" *ngIf="(walkInForm.controls.pgEducationDetail.value.length > 0 && walkInForm.controls.pgEducationDetail.value != ' ')">
                                <div *ngIf="(isSubmit || walkInForm.controls.pgPassOutYear.touched && !walkInForm.controls.pgPassOutYear.value)">PassOutYear is required</div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="">Aggregate (%)
                                <b class="text-danger" *ngIf="(walkInForm.controls.pgEducationDetail.value.length > 0 && walkInForm.controls.pgEducationDetail.value != ' ')">*</b>
                              </label>
                              <input type="text" class="form-control" formControlName="pgAggregate"  [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.pgAggregate.touched) && walkInForm.controls.pgAggregate.errors }" (keypress)="keyPressNumbers($event)">
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.pgAggregate.touched) && walkInForm.controls.pgAggregate.errors?.max"><small>Provide Valid Aggregate(%)</small></div>
                              <div *ngIf="(walkInForm.controls.pgEducationDetail.value.length > 0 && walkInForm.controls.pgEducationDetail.value != ' ')">
                                <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.pgAggregate.touched)"> Aggregate is required</div>
                              </div>
                            </div>
                              <div class="form-group">
                                  <label for="">Skills<sup class="text-danger">*</sup></label>
                                  <textarea class="form-control" formControlName="skills" aria-label="With textarea" [ngClass]="{'invalidBorder' : (isSubmit || walkInForm.controls.skills.touched) && walkInForm.controls.skills.errors }"></textarea>
                                  <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.skills.touched) && walkInForm.controls.skills.errors?.required"><small>skills is Mandatory</small></div>
                              </div>
                               <select class="form-control"  formControlName="driveName">
                                <option hidden selected>drive names</option>
                                <option  *ngFor="let item of drivenames">{{item.driveLocation}}</option>
                               </select>
                              <!-- <select  formControlName="driveName" aria-placeholder="Drive Location"  aria-label="Default select example">
                                <option hidden selected>Drive Names</option>
                                <option  *ngFor="let item of drivenames">{{item.driveLocation}}</option>
                              </select> -->
                              <br>
                            <div>
                              <label for="">Ready For PAN India?<sup class="text-danger">*</sup></label><br>
                            </div>

                              <div class="d-flex flex-row ">
                                <div class="form-check">
                                  <input class="form-check-input" formControlName="panIndia" type="radio" name="panIndia" value="Yes" >
                                  <label class="form-check-label" for="exampleRadios1">
                                    Yes
                                  </label>
                                </div>
                                <div class="form-check pl-5">
                                  <input class="form-check-input" formControlName="panIndia"  type="radio" name="panIndia"  value="No">
                                  <label class="form-check-label" for="exampleRadios2">
                                    No
                                  </label>
                                </div>
                              </div>
                              <div class="text-danger" *ngIf="(isSubmit || walkInForm.controls.panIndia.touched) && walkInForm.controls.panIndia.errors?.required"><small>This Field is Mandatory</small></div>

                              <!-- <div class="form-group">
                                <label for="">Are you from Jspider or Qspiders</label><br>
                                <input type="checkbox" value="Yes" formControlName="source"> <label for="" class="pl-2">if YES</label>
                              </div> -->
                              <label for="">Are you from Jspider or Qspiders</label><br>
                              <div class="form-check">
                                <input class="form-check-input"  formControlName="source" type="checkbox" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                  if YES
                                </label>
                              </div>
                              <div class="text-center my-3">
                                  <button type="submit" class="btn submitBtn"  style="background-color: rgb(21 99 139); color: white;"   (click)="onSubmit(walkInForm)">SUBMIT APPLICATION</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<div class="mt-5" *ngIf="toster" class="errormessagecard">
  <div class="card  m-auto" style="width: 64% ; ">
      <div class="card-body" style=" text-align: center; font-size: large;">
          {{tosterMessage}}
      </div>
  </div>
</div>
<div class="text-center  d-flex justify-content-center align-items-center" *ngIf='showLoadingIndicator'>
  <img src="../../assets/images//Spinner-2.svg" alt="Spinner" class="spinner" style="position: relative;">
</div>

