<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1 class="display-3">404 PAGE NOT FOUND</h1>
    <p class="lead">The URL entered is wrong</p>
    <hr class="my-2">
    <p>Please go back to Home page</p>
    <p class="lead">
      <a class="btn btn-primary btn-lg" routerLink="/" role="button">
        Home
      </a>
    </p>
  </div>
</div>
