<!-- <section class="page-title abt-bg">
    <div class="col-lg-12 text-center">
        <div style="display: inline-block; vertical-align: super">
            <div class="title-branch-menu" style="vertical-align: super">
                Case Studies <span class="text-white">&nbsp;> &nbsp;</span>
            </div>
        </div>
        <div style="display: inline-block">
            <h1 class="text-lg">Hancom’s VBA IDE Feature</h1>
        </div>
    </div>
</section> -->
<section>
    <div>
        <img src="assets/images/Case study/Technology.png" alt="" style="position: relative;">
    </div>
</section>

<div class="container-fluid">
    <!-- <div class="row mb-5 mt-5">
        <div class="col-md-2"></div>
        <div class="col-md-10">
            <h4 class="text-secondary">Company</h4>
            <h1 class="mt-4 company-name">Hancom</h1>
        </div>
    </div> -->

    <div class="row mt-5">
        <div class="col-md-2 mt-4"></div>

        <div class="col-md-8 mt-4">
            <div class="row">
                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Region.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-map-marker-alt"></i> -->
                    </div>
                    <h4 class="title">Region</h4>
                    <h2>South Korea</h2>
                </div>

                <div class="col-md-4 details">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Industry.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-industry"></i> -->
                    </div>
                    <h4 class="title">Industry</h4>
                    <h2>Technology</h2>
                </div>

                <div class="col-md-4 details1">
                    <div class="detail-icon">
                        <img src="assets/images/Case study/Technologies.png" alt="" style="position: relative; max-width: 30% !important;">
                        <!-- <i class="fas fa-laptop-code"></i> -->
                    </div>
                    <h4 class="title">Technologies</h4>
                    <h2>Vuejs, Storybook, Atomic design principles, with Typescript</h2>
                </div>
            </div>
        </div>

        <div class="col-md-2 mt-4"></div>
    </div>

    <!-- <div class="text-center mt-5">
  </div> -->

    <div class="row mt-5 mb-5">
        <div class="col-md-1"></div>

        <div class="col-md-10">
            <div class="row">
                <div class="col-md-12">
                    <div class="card border-0 block1">
                        <div class="detail-icon1">
                            <img src="assets/images/Case study/requirement.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="fas fa-clipboard"></i> -->
                        </div>
                        <h3 style="text-align: center;">Requirement</h3>
                        <p>
                            The company is an office suite software developer, that has developed an all-in-one Office-compatible productivity suite. The suite comprises Word, Cell, Show, Mail, PDF, and Word Processor. Under Cell, the company wanted to add the VBA IDE feature, which
                            is why they connected to us.

                        </p>
                    </div>
                    <div class="card border-0 block2">
                        <div class="detail-icon2">
                            <img src="assets/images/Case study/challenges.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-lightbulb"></i> -->
                        </div>
                        <h3 style="text-align: center;">Challenges</h3>
                        <p>
                            The company was finding it difficult to develop and implement the VBA IDE feature, which is why they wanted to outsource the feature. The whole idea was to include drag and drop features, for which tracking each and every point was mandatory. They wanted
                            to include controls such as checkbox, options button, input box, textbox, dropdown, list box, combo box, toggle button, multi-page, tab page, having the drag and drop feature for all of this was very challenging.
                        </p>
                    </div>
                    <div class="card border-0 block3">
                        <div class="detail-icon3">
                            <img src="assets/images/Case study/result.png" alt="" style="position: relative; max-width: 8% !important;">
                            <!-- <i class="far fa-thumbs-up"></i> -->
                        </div>
                        <h3 style="text-align: center;">Result</h3>
                        <p>
                            A user can now build, run, and edit Excel-compatible macros in Cell. One can use the VBA Script Editor to edit the macros attached to a workbook. Users can simply drag n drop these controls and create their own user interface, and create a form or table
                            that can interact with Cell.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
