export const environment = {
  production: false,
  secretKey: '6LfFILUgAAAAAFYUPCUPOHNB4xkGe_gHnoirGvE_',
  //  baseUrl: 'https://tewebsite-backend.web.app'
  //  baseUrl: 'https://api.technoelevate.com'
  // baseUrl: 'http://localhost:3000',
  baseUrl: 'http://10.10.20.98:9092',
  apiUrl:"http://49.249.28.218"

};
